import React from 'react';
import ReactCompareImage from 'react-compare-image';


function BeforeAfter(props) {
    const {left, right} = props;
    return (
        <div>
            <ReactCompareImage
                sliderLineWidth={3}
                sliderLineColor="black"
                leftImage={left}
                rightImage={right}
            />

        </div>
    );
}

export default BeforeAfter;
