import React from 'react';
import './arabkir.scss'

import {useTranslate} from "react-redux-multilingual";
import BeforeAfter from "../../../common/before-after/before-after";
import Heading from "../../../common/heading/heading";


function BuildingDetailsSection(props) {
    const {setSelectedItem} = props;
    const t = useTranslate();

    return (
        <div>
            <div className="container" style={{marginTop: '5%'}}>
                <div className="container">
                    <div className="row noselect" style={{alignItems: 'center'}}>
                        <div className="col-lg-4 col-md-3 d-flex" style={{justifyContent: 'center'}}>
                            <div className="featured-box style-2" style={{boxShadow: '0 0 10px hsl(0deg 0% 50% / 30%)'}}>
                                <div className="featured-box-wrap" style={{cursor: 'pointer'}} onClick={()=> {
                                    setSelectedItem(1, "A");
                                }}>
                                    <div className="featured-box-top">
                                        <div className="featured-box-icon"><i className="fa fa-building"/></div>
                                        <h3 className="featured-box-title text-uppercase font-weight-bold" style={{fontSize: '22px', fontFamily: 'sans-serif'}}>{t('A') + ' ' + t('building')} </h3>
                                    </div>
                                    <div className="featured-box-bottom">
                                        <p className="featured-box-description" style={{fontSize: '20px'}}>8  {' ' + t('floors')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row noselect">
                        <div className="col-lg-4 col-md-3 d-flex" style={{justifyContent: 'center'}}>
                            <div className="featured-box style-2" style={{boxShadow: '0 0 10px hsl(0deg 0% 50% / 30%)'}}>
                                <div className="featured-box-wrap" style={{cursor: 'pointer'}} onClick={()=> {
                                    setSelectedItem(1, "B");
                                }}>
                                    <div className="featured-box-top">
                                        <div className="featured-box-icon"><i className="fa fa-building"/></div>
                                        <h3 className="featured-box-title text-uppercase font-weight-bold" style={{fontSize: '22px', fontFamily: 'sans-serif'}}>{t('B') + ' ' + t('building')}</h3>
                                    </div>
                                    <div className="featured-box-bottom">
                                        <p className="featured-box-description" style={{fontSize: '20px'}}>8 {' ' + t('floors')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row noselect">
                        <div className="col-lg-4 col-md-3 d-flex" style={{justifyContent: 'center'}}>
                            <div className="featured-box style-2" style={{boxShadow: '0 0 10px hsl(0deg 0% 50% / 30%)'}}>
                                <div className="featured-box-wrap" style={{cursor: 'pointer'}} onClick={()=> {
                                    setSelectedItem(-1, "A");
                                }}>
                                    <div className="featured-box-top">
                                        <div className="featured-box-icon"><i className="fa fa-car"></i></div>
                                        <h3 className="featured-box-title text-uppercase font-weight-bold" style={{fontSize: '22px', fontFamily: 'sans-serif'}}>Parking</h3>
                                    </div>
                                    <div className="featured-box-bottom">
                                        <p className="featured-box-description" style={{fontSize: '20px'}}>2 {' ' + t('floors')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BuildingDetailsSection