import React, {Component} from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Heading from "../../common/heading/heading";
import {withTranslate} from "react-redux-multilingual";

class GallerrySection extends Component {
    state = {
        isOpen: false
    }

    open(e, image) {
        e.preventDefault()
        this.image = image
        this.setState({isOpen: true})
    }

    render() {
        const {translate} = this.props;

        return (
            <>
                <Heading>{translate('gallery')}</Heading>
                <div className="gallerry-wrap p-0">
                    <div className={`row${this.props.flexRow ? ' flex-row-reverse' : ''}`}>
                        <div className="col-lg-3 col-md-6 col-md-12">
                            <div className="gallerry-image">
                                <a href="#" onClick={(e) => this.open(e, '/assets/images/general/6.JPG')}>
                                    <img className="img-fluid" src="/assets/images/general/6.JPG" alt=""/>
                                    <div className="gallery-item-overlay"><i className="opal-icon-zoom"></i></div>
                                </a>
                            </div>
                            <div className="gallerry-image">
                                <a href="#" onClick={(e) => this.open(e, '/assets/images/general/7.JPG')}>
                                    <img className="img-fluid" src="/assets/images/general/7.JPG" alt=""/>
                                    <div className="gallery-item-overlay"><i className="opal-icon-zoom"></i></div>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-md-12">
                            <div className="gallerry-image">
                                <a href="#" onClick={(e) => this.open(e, '/assets/images/general/2.jpg')}>
                                    <img className="img-fluid" src="/assets/images/general/2.jpg"alt=""/>
                                    <div className="gallery-item-overlay"><i className="opal-icon-zoom"></i></div>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-md-12">
                            <div className="gallerry-image">
                                <a href="#" onClick={(e) => this.open(e, '/assets/images/general/5.JPG')}>
                                    <img className="img-fluid" src="/assets/images/general/5.JPG" alt=""/>
                                    <div className="gallery-item-overlay"><i className="opal-icon-zoom"></i></div>
                                </a>
                            </div>
                            <div className="gallerry-image">
                                <a href="#" onClick={(e) => this.open(e, '/assets/images/general/8.JPG')}>
                                    <img className="img-fluid" src="/assets/images/general/8.JPG" alt=""/>
                                    <div className="gallery-item-overlay"><i className="opal-icon-zoom"></i></div>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-md-12">
                            <div className="gallerry-image">
                                <a href="#" onClick={(e) => this.open(e, '/assets/images/general/1.jpg')}>
                                    <img className="img-fluid" src="/assets/images/general/1.jpg"alt=""/>
                                    <div className="gallery-item-overlay"><i className="opal-icon-zoom"></i></div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.isOpen &&
                    <Lightbox onCloseRequest={() => this.setState({isOpen: false})} mainSrc={this.image}/>
                }
            </>
        )
    }
}
export default withTranslate(GallerrySection);