import React, {Component} from 'react';
import CountUp from 'react-countup';
import './countup.scss';
import {connect} from "react-redux";
import {withTranslate} from "react-redux-multilingual";
class CounterBlock extends Component {
    render (){
        const {translate} = this.props;
        return (
            <div className={`countup-wrap`}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 col-sm-6">
                            <CountUp start={0} end={1200} delay={0}>
                                {({ countUpRef }) => (
                                    <div className="countup-box">
                                        <div className="counter-number">
                                            <span ref={countUpRef} />
                                        </div>

                                        <div className="counter-title">
                                            <span>{translate('square_areas')}</span>
                                        </div>
                                    </div>
                                )}
                            </CountUp>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <CountUp start={0} end={23} delay={0}>
                                {({ countUpRef }) => (
                                    <div className="countup-box">
                                        <div className="counter-number">
                                            <span ref={countUpRef} />
                                        </div>

                                        <div className="counter-title">
                                            <span>{translate('parking')}</span>
                                        </div>
                                    </div>
                                )}
                            </CountUp>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <CountUp start={0} end={56} delay={0}>
                                {({ countUpRef }) => (
                                    <div className="countup-box">
                                        <div className="counter-number">
                                            <span ref={countUpRef} />
                                        </div>

                                        <div className="counter-title">
                                            <span>{translate('apartments')}</span>
                                        </div>
                                    </div>
                                )}
                            </CountUp>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <CountUp start={0} end={320} delay={0}>
                                {({ countUpRef }) => (
                                    <div className="countup-box">
                                        <div className="counter-number">
                                            <span ref={countUpRef} />
                                        </div>

                                        <div className="counter-title">
                                            <span>{translate('green_areas')}</span>
                                        </div>
                                    </div>
                                )}
                            </CountUp>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        locale: state.Intl.locale
    }
};

export default connect(mapStateToProps)(withTranslate(CounterBlock));