import React, {Component} from 'react';
import Slider from "react-slick";
import './slider.scss';
import {connect} from "react-redux";
import {withTranslate} from "react-redux-multilingual";


class CarouselPage2 extends Component {
    constructor(props) {
        super(props);
        const {translate} = this.props;

        this.data = [
            {
                image: `/assets/images/general/wall.jpg`,
                title: translate('slider1Title'),
                description: translate('slider1Text'),
                link: ''
            },
            {
                image: `/assets/images/general/smartia1.jpg`,
                title: translate('slider2Title'),
                description: translate('slider2Text'),
                link: 'https://alumil.ua/produkciya/razdvizhnye-dveri/s560.html'
            },
            {
                image: `/assets/images/general/elevator.png`,
                title: translate('slider3Title'),
                description: translate('slider3Text'),
                link: 'https://ilift.am/ '
            },
            {
                image:  `/assets/images/general/ktrvacq.jpg`,
                title: translate('slider4Title'),
                description: translate('slider4Text'),
                link: 'https://www.mottura.it/ru/homepage-russian'
            }
        ]
        this.state = {
            active: this.data[0],
            slideIndex: 0
        }
    }

    slideChange = (old, index) => {
        this.setState({
            active: this.data[index],
            slideIndex: index
        })
    }

    checkActive(index) {
        let className = `bullet-item`;
        if (index === this.state.slideIndex) {
            className += ` selected`
        }
        return className;
    }

    clickDot = (index) => {
        this.slider.slickGoTo(index)
    }

    render() {
        const {translate} = this.props;
        const settings = {
            dots: false,
            arrows: false,
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
        };

        return (
            <div className={'carousel2-wrap position-relative'}>
                <div className="row flex-md-row-reverse">
                    <div className="col-lg-6 col-md-12">
                        <Slider {...settings} ref={slider => (this.slider = slider)} beforeChange={this.slideChange}>
                            {this.data.map((data, index) =>
                                <div className="slider-item" key={index}>
                                    <img src={data.image} alt="" className="img-fluid"/>
                                </div>
                            )}
                        </Slider>
                    </div>
                    <div className="col-lg-4 col-md-10  d-flex align-items-center">
                        <div className="slider-content">
                            <div className="slider-info">
                                <h3 className="text-white text-uppercase mb-4">{this.state.active.title}</h3>
                                <p className="slider-desc" style={{whiteSpace: 'pre-line', textIndent: '15px'}}>{this.state.active.description}</p>
                                {this.state.active.link && <a className="btn btn-link p-0" target="_blank" href={this.state.active.link}>{translate('more_info')}</a> }
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-2 d-flex align-items-center ">
                        <div className="navigation">
                            <ul className="bullets-vertical">
                                {this.data.map((item, index) =>
                                    <li className={this.checkActive(index)} key={index} onClick={() => this.clickDot(index)}>
                                        <span className="bullet-inner"></span></li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        locale: state.Intl.locale
    }
};

export default connect(mapStateToProps)(withTranslate(CarouselPage2));
