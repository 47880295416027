import React, {Component} from 'react';
import './amenities.scss';
import Heading from "../../common/heading/heading";
import CarouselPage1 from "../../common/slider/slider1";
import {connect} from "react-redux";
import {withTranslate} from "react-redux-multilingual";

class AmenitiesSection extends Component {
    render (){
        const {translate} = this.props;

        return (
            <div className="row align-items-center">
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <CarouselPage1/>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 content-right">
                    <Heading titleClass="titleClass">{translate('amenities_title')}</Heading>
                    <p className="mb-5">
                        {translate('amenities_info')}
                    </p>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="icon-list">
                                <div className="icon-list-wrap">
                                    <ul>
                                        <li className="icon-list-items">
                                                <span className="icon-list-icon">
                                                    <i className="fa fa-angle-right"></i>
                                                </span>
                                            <span className="icon-list-text noselect">{translate('amenities_1')}</span>
                                        </li>
                                        <li className="icon-list-items">
                                                <span className="icon-list-icon">
                                                    <i className="fa fa-angle-right"></i>
                                                </span>
                                            <span className="icon-list-text noselect" style={{color: '#3f4448'}}>{translate('amenities_2')}</span>
                                        </li>
                                        <li className="icon-list-items">
                                                <span className="icon-list-icon">
                                                    <i className="fa fa-angle-right"></i>
                                                </span>
                                            <span className="icon-list-text noselect" style={{color: '#3f4448'}}>{translate('amenities_3')}</span>
                                        </li>
                                        <li className="icon-list-items">
                                                <span className="icon-list-icon">
                                                    <i className="fa fa-angle-right"></i>
                                                </span>
                                            <span className="icon-list-text noselect " style={{color: '#3f4448'}}>{translate('amenities_4')}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="icon-list">
                                <div className="icon-list-wrap">
                                    <ul>
                                        <li className="icon-list-items">
                                                <span className="icon-list-icon">
                                                    <i className="fa fa-angle-right"></i>
                                                </span>
                                            <span className="icon-list-text noselect" style={{color: '#3f4448'}}>{translate('amenities_5')}</span>
                                        </li>
                                        <li className="icon-list-items">
                                                <span className="icon-list-icon">
                                                    <i className="fa fa-angle-right"></i>
                                                </span>
                                            <span className="icon-list-text noselect" style={{color: '#3f4448'}}>{translate('amenities_6')}</span>
                                        </li>
                                        <li className="icon-list-items">
                                                <span className="icon-list-icon">
                                                    <i className="fa fa-angle-right"></i>
                                                </span>
                                            <span className="icon-list-text noselect" style={{color: '#3f4448'}}>{translate('amenities_7')}</span>
                                        </li>
                                        <li className="icon-list-items">
                                                <span className="icon-list-icon">
                                                    <i className="fa fa-angle-right"></i>
                                                </span>
                                            <span className="icon-list-text noselect" style={{color: '#3f4448'}}>{translate('amenities_8')}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        locale: state.Intl.locale
    }
};

export default connect(mapStateToProps)(withTranslate(AmenitiesSection));