import React, {useState} from 'react';
import Helmet from 'react-helmet';
import './arabkir.scss';
import ApartmentDetails from "./apartment-details";
import ApartmentContainer from "./apartment-container";
import BuildingContainer from "./building-container";


function ArabkirBuilding(props) {
    const {data} = props;

    const data1 = [
        {
            id: "1",
            points: "96.5,0.5 763.5,3.5 764.5,151.5 480.5,154.5 485.5,164.5 80.5,164.5 80.5,115.5 114.5,114.5 119.5,55.5 96.5,36.5 98.5,1.5",
            floor: 8,
            available: "0",
            popupX: "880",
            popupY: "0",
            buildingName: "A",
            buildingTextX: "960",
            buildingTextY: "130",
            floorTextX: "1080",
            floorTextY: "70",
            availableTextX: "1080",
            availableTextY: "150",
        },
        {
            id: "2",
            points: "80.7333,164.733 485.733,163.733 480.733,154.733 764.733,152.733 764.733,255.733 506.733,256.733 505.733,265.733 80.7333,268.733 80.7333,164.733",
            floor: 7,
            available: "0",
            popupX: "880",
            popupY: "100",
            buildingName: "A",
            buildingTextX: "960",
            buildingTextY: "230",
            floorTextX: "1080",
            floorTextY: "170",
            availableTextX: "1080",
            availableTextY: "250",
        },
        {
            id: "3",
            points: "80.67,269.83 509.67,265.83 507.67,257.83 764.67,255.83 765.67,368.83 507.67,368.83 505.67,377.83 80.67,377.83 80.67,268.83 507.67,265.83",
            floor: 6,
            available: "0",
            popupX: "880",
            popupY: "220",
            buildingName: "A",
            buildingTextX: "960",
            buildingTextY: "350",
            floorTextX: "1080",
            floorTextY: "290",
            availableTextX: "1080",
            availableTextY: "370",
        },
        {
            id: "4",
            points: "80.6667,378.833 505.667,377.833 507.667,369.833 765.667,368.833 766.667,482.833 80.6667,487.833 80.6667,377.833",
            floor: 5,
            available: "0",
            popupX: "880",
            popupY: "340",
            buildingName: "A",
            buildingTextX: "960",
            buildingTextY: "470",
            floorTextX: "1080",
            floorTextY: "410",
            availableTextX: "1080",
            availableTextY: "490",
        },
        {
            id: "5",
            points: "80.6667,487.733 766.667,482.733 766.567,595.733 80.5667,595.733 80.5667,486.733",
            floor: 4,
            available: "0",
            popupX: "880",
            popupY: "440",
            buildingName: "A",
            buildingTextX: "960",
            buildingTextY: "570",
            floorTextX: "1080",
            floorTextY: "510",
            availableTextX: "1080",
            availableTextY: "590",
        },
        {
            id: "6",
            points: "80.6667,596.533 766.667,595.533 767.567,703.533 80.5667,702.533 80.5667,596.533",
            floor: 3,
            available: "0",
            popupX: "880",
            popupY: "550",
            buildingName: "A",
            buildingTextX: "960",
            buildingTextY: "680",
            floorTextX: "1080",
            floorTextY: "620",
            availableTextX: "1080",
            availableTextY: "700",
        },
        {
            id: "7",
            points: "79.7333,702.533 767.5,703.533 767.567,814.533 81.5667,810.533 80.5667,701.533",
            floor: 2,
            available: "0",
            popupX: "880",
            popupY: "660",
            buildingName: "A",
            buildingTextX: "960",
            buildingTextY: "790",
            floorTextX: "1080",
            floorTextY: "730",
            availableTextX: "1080",
            availableTextY: "810",
        },
        {
            id: "8",
            points: "82.5667,812.2 82.5667,859.2 63.5667,870.2 18.5667,893.2 760.567,893.2 763.567,892.2 765.567,868.2 764.567,815.2 83.5667,811.2",
            floor: 1,
            available: "0",
            popupX: "880",
            popupY: "770",
            buildingName: "A",
            buildingTextX: "960",
            buildingTextY: "900",
            floorTextX: "1080",
            floorTextY: "840",
            availableTextX: "1080",
            availableTextY: "920",
        },
        {
            id: "9",
            points: "764.467,155.467 762.967,2.96667 1435.47,1.46667 1435.47,31.4667 1415.47,46.4667 1419.47,108.467 1453.47,108.467 1455.47,159.467 1041.47,161.467 1048.47,151.467 763.467,152.467",
            floor: 8,
            available: "4",
            popupX: "150",
            popupY: "0",
            buildingName: "B",
            buildingTextX: "230",
            buildingTextY: "130",
            floorTextX: "350",
            floorTextY: "70",
            availableTextX: "350",
            availableTextY: "150",
        },
        {
            id: "10",
            points: "764.667,151.667 1048.67,151.667 1041.67,160.667 1455.67,159.667 1455.67,264.667 1020.67,262.667 1020.67,255.667 764.667,254.667 764.667,150.667",
            floor: 7,
            available: "0",
            popupX: "150",
            popupY: "100",
            buildingName: "B",
            buildingTextX: "230",
            buildingTextY: "230",
            floorTextX: "350",
            floorTextY: "170",
            availableTextX: "350",
            availableTextY: "250",
        },
        {
            id: "11",
            points: "764.8,254.967 1020.8,255.967 1020.8,262.967 1454.8,264.967 1454.8,374.967 1021.8,376.967 1020.8,369.967 764.8,368.967 764.8,254.967",
            floor: 6,
            available: "2",
            popupX: "150",
            popupY: "220",
            buildingName: "B",
            buildingTextX: "230",
            buildingTextY: "350",
            floorTextX: "350",
            floorTextY: "290",
            availableTextX: "350",
            availableTextY: "370",
        },
        {
            id: "12",
            points: "764.667,369.667 1020.67,370.667 1021.67,377.667 1454.67,375.667 1454.67,484.667 765.667,482.667 766.667,369.667",
            floor: 5,
            available: "4",
            popupX: "150",
            popupY: "340",
            buildingName: "B",
            buildingTextX: "230",
            buildingTextY: "470",
            floorTextX: "350",
            floorTextY: "410",
            availableTextX: "350",
            availableTextY: "490",
        },
        {
            id: "13",
            points: "766.667,482.667 1454.67,484.667 1454.67,593.667 766.667,595.667 766.667,482.667",
            floor: 4,
            available: "4",
            popupX: "150",
            popupY: "440",
            buildingName: "B",
            buildingTextX: "230",
            buildingTextY: "570",
            floorTextX: "350",
            floorTextY: "510",
            availableTextX: "350",
            availableTextY: "590",
        },
        {
            id: "14",
            points: "766.667,595.667 1454.67,593.667 1454.67,705.667 767.667,703.667 766.667,594.667",
            floor: 3,
            available: "4",
            popupX: "150",
            popupY: "550",
            buildingName: "B",
            buildingTextX: "230",
            buildingTextY: "680",
            floorTextX: "350",
            floorTextY: "620",
            availableTextX: "350",
            availableTextY: "700",
        },
        {
            id: "15",
            points: "767.667,704 1454.67,706 1454.67,813 768.667,814 766.667,703",
            floor: 2,
            available: "4",
            popupX: "150",
            popupY: "660",
            buildingName: "B",
            buildingTextX: "230",
            buildingTextY: "790",
            floorTextX: "350",
            floorTextY: "730",
            availableTextX: "350",
            availableTextY: "810",
        },
        {
            id: "16",
            points: "764.667,815 765.667,868 763.667,893 1520.03,893.867 1454.03,863.867 1454.03,811.867 764.033,814.867",
            floor: 1,
            available: "4",
            popupX: "150",
            popupY: "770",
            buildingName: "B",
            buildingTextX: "230",
            buildingTextY: "900",
            floorTextX: "350",
            floorTextY: "840",
            availableTextX: "350",
            availableTextY: "920",
        },
    ];
    const apartmentListData1 = [
        {
            id: "1",
            points: "21.2242,298.388 72.4121,642.97 198.509,611.758 186.024,553.079 219.109,544.964 203.503,465.685 369.552,428.842 313.37,229.721 19.9758,297.139",
            apartmentId: "1",
            sold: true,
            booked: false,
            soldPopupX: "80",
            soldPopupY: "380",
            soldTextX: "190",
            soldTextY: "405",
            popupX: "0",
            popupY: "70",
            price: '42.000 $',
            priceX: "110",
            priceY: "100",
            availabilityX: "110",
            availabilityY: "135",
            lineX1: "20",
            lineX2: "210",
            lineY1: "155",
            lineY2: "155",
            roomName: "Սենյակ:         3",
            roomNameX: "95",
            roomNameY: "180",
            roomSquare: "Մակերես:    107.2 մ²",
            roomSquareX: "120",
            roomSquareY: "220",
            room: 2,
            area: 107.2,
            floor: 3,
            ceilingHeight: 3,
            code: 'C01'
        },
        {
            id: "2",
            points: "312.739,229.709 505.636,188.521 459.442,73.6606 742.849,16.2303 923.879,374.545 419.491,500.642 397.655,422.624 368.915,428.867 313.37,229.721",
            apartmentId: "2",
            sold: false,
            booked: false,
            popupX: "670",
            popupY: "450",
            price: '101.000 $',
            priceX: "780",
            priceY: "480",
            availabilityX: "780",
            availabilityY: "515",
            lineX1: "690",
            lineX2: "880",
            lineY1: "535",
            lineY2: "535",
            roomName: "Սենյակ:         2",
            roomNameX: "760",
            roomNameY: "560",
            roomSquare: "Մակերես:    103 մ²",
            roomSquareX: "780",
            roomSquareY: "600",
            room: 2,
            area: 107.2,
            floor: 3,
            ceilingHeight: 3,
            code: 'C01'
        }
    ];
    const apartmentListData2 = [
        {
            id: "1",
            points: "201.006,943.855 387.03,893.915 273.418,540.594 302.133,534.352 279.661,465.685 373.297,441.964 285.903,203.503 14.9818,267.176 202.255,943.855",
            apartmentId: "1",
            sold: true,
            booked: false,
            soldPopupX: "80",
            soldPopupY: "380",
            soldTextX: "190",
            soldTextY: "405",
            popupX: "0",
            popupY: "70",
            price: '42.000 $',
            priceX: "110",
            priceY: "100",
            availabilityX: "110",
            availabilityY: "135",
            lineX1: "20",
            lineX2: "210",
            lineY1: "155",
            lineY2: "155",
            roomName: "Սենյակ:         3",
            roomNameX: "95",
            roomNameY: "180",
            roomSquare: "Մակերես:    107.2 մ²",
            roomSquareX: "120",
            roomSquareY: "220",
            room: 2,
            area: 107.2,
            floor: 3,
            ceilingHeight: 3,
            code: 'C01'
        },
        {
            id: "2",
            points: "285.676,203.245 525.385,144.566 649.609,446.075 398.039,510.372 286.3,203.245",
            apartmentId: "2",
            sold: false,
            booked: false,
            popupX: "670",
            popupY: "450",
            price: '101.000 $',
            priceX: "780",
            priceY: "480",
            availabilityX: "780",
            availabilityY: "515",
            lineX1: "690",
            lineX2: "880",
            lineY1: "535",
            lineY2: "535",
            roomName: "Սենյակ:         2",
            roomNameX: "760",
            roomNameY: "560",
            roomSquare: "Մակերես:    103 մ²",
            roomSquareX: "780",
            roomSquareY: "600",
            room: 2,
            area: 107.2,
            floor: 3,
            ceilingHeight: 3,
            code: 'C01'
        },
        {
            id: "3",
            points: "412.399,579.561 673.332,508.398 780.077,775.573 502.914,854.852 412.399,578.937",
            apartmentId: "2",
            sold: false,
            booked: false,
            popupX: "670",
            popupY: "0",
            price: '67.000 $',
            priceX: "780",
            priceY: "30",
            availabilityX: "780",
            availabilityY: "65",
            lineX1: "690",
            lineX2: "880",
            lineY1: "85",
            lineY2: "85",
            roomName: "Սենյակ:         1",
            roomNameX: "760",
            roomNameY: "110",
            roomSquare: "Մակերես:    47.2 մ²",
            roomSquareX: "780",
            roomSquareY: "150",
            room: 2,
            area: 107.2,
            floor: 3,
            ceilingHeight: 3,
            code: 'C01'
        },
        {
            id: "4",
            points: "606.606,455.728 632.03,519.287 672.284,508.694 780.077,775.573 1081.18,691.957 760.208,15.0508 498.556,79.6694 650.039,445.135 606.606,456.787",
            apartmentId: "2",
            sold: false,
            booked: true,
            soldPopupX: "670",
            soldPopupY: "400",
            soldTextX: "785",
            soldTextY: "425",
            popupX: "70",
            popupY: "770",
            price: '42.000 $',
            priceX: "185",
            priceY: "800",
            availabilityX: "185",
            availabilityY: "830",
            lineX1: "90",
            lineX2: "280",
            lineY1: "850",
            lineY2: "850",
            roomName: "Սենյակ:         1",
            roomNameX: "165",
            roomNameY: "880",
            roomSquare: "Մակերես:    67.2 մ²",
            roomSquareX: "185",
            roomSquareY: "920",
            room: 2,
            area: 107.2,
            floor: 3,
            ceilingHeight: 3,
            code: 'C01'
        }
    ];
    const apartmentListData3 = [
        {
            id: "1",
            points: "138.582,916.388 338.339,868.945 245.952,498.145 277.164,493.152 260.933,431.976 352.073,415.745 369.552,470.679 642.97,412 555.576,178.533 104.873,267.176 91.1394,215.988 13.7333,230.97 22.4727,280.909 12.4848,282.158 138.582,915.139",
            apartmentId: "1",
            sold: true,
            booked: false,
            soldPopupX: "80",
            soldPopupY: "380",
            soldTextX: "190",
            soldTextY: "405",
            popupX: "0",
            popupY: "70",
            price: '42.000 $',
            priceX: "110",
            priceY: "100",
            availabilityX: "110",
            availabilityY: "135",
            lineX1: "20",
            lineX2: "210",
            lineY1: "155",
            lineY2: "155",
            roomName: "Սենյակ:         3",
            roomNameX: "95",
            roomNameY: "180",
            roomSquare: "Մակերես:    107.2 մ²",
            roomSquareX: "120",
            roomSquareY: "220",
            not_available_popupX: '100',
            not_available_popupY: '100',
            not_available_textX: '100',
            not_available_textY: '100',
            room: 2,
            area: 107.2,
            floor: 3,
            ceilingHeight: 3,
            code: 'C01'
        },
        {
            id: "2",
            points: "369.299,470.91 476.669,835.468 1088.43,695.637 778.802,13.9646 474.172,73.8919 517.869,185.007 555.323,178.765 643.029,412.544 369.299,470.91",
            apartmentId: "2",
            sold: false,
            booked: false,
            popupX: "670",
            popupY: "450",
            price: '101.000 $',
            priceX: "780",
            priceY: "480",
            availabilityX: "780",
            availabilityY: "515",
            lineX1: "690",
            lineX2: "880",
            lineY1: "535",
            lineY2: "535",
            roomName: "Սենյակ:         2",
            roomNameX: "760",
            roomNameY: "560",
            roomSquare: "Մակերես:    103 մ²",
            roomSquareX: "780",
            roomSquareY: "600",
            room: 2,
            area: 107.2,
            floor: 3,
            ceilingHeight: 3,
            code: 'C01'
        }
    ];
    const parkingData1 = [
        {
            id: "1",
            points: "24.3976,131.899 208.904,133.424 209.667,237.876 22.8727,237.876 24.3976,132.662",
            apartmentId: "1",
            sold: true,
            booked: false,
            soldPopupX: "1180",
            soldPopupY: "380",
            soldTextX: "11190",
            soldTextY: "405",
            popupX: "1110",
            popupY: "70",
            price: '42.000 $',
            priceX: "111110",
            priceY: "100",
            availabilityX: "11110",
            availabilityY: "135",
            lineX1: "1120",
            lineX2: "111210",
            lineY1: "155",
            lineY2: "155",
            roomName: "Սենյակ:         3",
            roomNameX: "1195",
            roomNameY: "180",
            roomSquare: "Մակերես:    107.2 մ²",
            roomSquareX: "11120",
            roomSquareY: "220",
            not_available_popupX: '11100',
            not_available_popupY: '100',
            not_available_textX: '111100',
            not_available_textY: '100',
            room: 2,
            area: 107.2,
            floor: 3,
            ceilingHeight: 3,
            code: 'C01'
        },
        {
            id: "2",
            points: "22.1103,237.876 208.904,237.876 209.667,340.041 23.6352,340.041 22.8727,237.114",
            apartmentId: "2",
            sold: false,
            booked: false,
            popupX: "1670",
            popupY: "450",
            price: '101.000 $',
            priceX: "1780",
            priceY: "480",
            availabilityX: "780",
            availabilityY: "515",
            lineX1: "1690",
            lineX2: "1880",
            lineY1: "535",
            lineY2: "535",
            roomName: "Սենյակ:         2",
            roomNameX: "1760",
            roomNameY: "560",
            roomSquare: "Մակերես:    103 մ²",
            roomSquareX: "1780",
            roomSquareY: "600",
            room: 2,
            area: 107.2,
            floor: 3,
            ceilingHeight: 3,
            code: 'C01'
        }
    ];

    const [hoveredId, setHoveredId] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedApartment, setSelectedApartment] = useState(null);
    const [selectedBuilding, setSelectedBuilding] = useState(null);
    const [selectedFloor, setSelectedFloor] = useState(null);


    function getImgUrl() {
        if (selectedFloor === 4 && selectedBuilding === 'B') {
            return `${process.env.PUBLIC_URL}/assets/images/floors/2hark1100.png`;
        } else if (selectedFloor === 1) {
            return `${process.env.PUBLIC_URL}/assets/images/floors/0hark1100.png`;
        } else if (selectedFloor === 6) {
            return `${process.env.PUBLIC_URL}/assets/images/floors/2hark1100.png`;
        } else if (selectedFloor === 2 || selectedFloor === 4) {
            return `${process.env.PUBLIC_URL}/assets/images/floors/2hark1100-updated.png`;
        } else if (selectedFloor === 5 || selectedFloor === 7) {
            return `${process.env.PUBLIC_URL}/assets/images/floors/3hark1100.png`;
        } else if (selectedFloor === 3 ) {
            return `${process.env.PUBLIC_URL}/assets/images/floors/3hark1100-updated.png`;
        } else if (selectedFloor === -1) {
            return `${process.env.PUBLIC_URL}/assets/images/floors/-1hark1100.png`;
        } else if (selectedFloor === -2) {
            return `${process.env.PUBLIC_URL}/assets/images/floors/-1hark1100.png`;
        } else {
            return `${process.env.PUBLIC_URL}/assets/images/floors/4hark1100.png`;
        }
    }

    function getFloorData() {
        if (selectedFloor === 1) {
            return apartmentListData1;
        } else if (selectedFloor === 2 || selectedFloor === 4 || selectedFloor === 6) {
            return apartmentListData2;
        } else if (selectedFloor === 3 || selectedFloor === 5 || selectedFloor === 7) {
            return apartmentListData2;
        } else if (selectedFloor === -1) {
            return [];
        } else if (selectedFloor === -2) {
            return parkingData1;
        } else {
            return apartmentListData3;
        }
    }

    function getViewBox() {
        return "0 0 1100 1030";
    }

    function getCurrentState() {
        if (selectedApartment) {
            return (
                <ApartmentDetails data={data} selectedBuilding={selectedBuilding}
                                  selectedFloor={selectedFloor} selectedApartment={selectedApartment}
                                  setSelectedApartment={setSelectedApartment}/>
            );
        }

        if (selectedBuilding) {
            return (<ApartmentContainer selectedItem={selectedItem}
                                        apartmentListData={data}
                                        selectedApartment={selectedApartment}
                                        setSelectedApartment={setSelectedApartment}
                                        selectedBuilding={selectedBuilding} setSelectedBuilding={setSelectedBuilding}
                                        selectedFloor={selectedFloor} setSelectedFloor={setSelectedFloor}
                                        viewBox={getViewBox()}
                                        hoveredId={hoveredId} imgUrl={getImgUrl()}/>);
        } else {
            return (
                <BuildingContainer data={data} hoveredId={hoveredId} setHoveredId={setHoveredId}
                                   selectedItem={selectedItem}
                                   setSelectedItem={(floor, buildingName) => {
                                       setSelectedBuilding(buildingName);
                                       setSelectedFloor(floor ? floor : 1);
                                   }}/>)
        }
    }

    return (
        <div>
            <Helmet>
                <title>City Premium Realty </title>
            </Helmet>
            {
                getCurrentState()
            }
        </div>
    )
}

export default ArabkirBuilding