import React, {Component} from 'react';
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import Helmet from 'react-helmet';
import './contact.scss';
import {withTranslate} from "react-redux-multilingual";

class ContactPage extends Component {
    render() {
        const {translate} = this.props;
        return (
            <div className={`contact-page-wrap`}>
                <Helmet>
                    <title>City Premium Realty | Contact Us</title>
                </Helmet>
                <Breadcrumb title={'contact_us'}/>
                <div className="section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <div className="box-icon">
                                    <div className="icon"><i className="opal-icon-phone"></i></div>
                                    <div className="content">
                                        <span className="title text-uppercase">{translate('phone')} :</span>
                                        <p className="description">(011) 50-00-11</p>
                                        <p className="description">(+374) 33 511 500</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <div className="box-icon">
                                    <div className="icon"><i className="opal-icon-point"></i></div>
                                    <div className="content">
                                        <span className="title text-uppercase">{translate('address')} :</span>
                                        <p className="description">{translate('office_location_info')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <div className="box-icon no-border">
                                    <div className="icon"><i className="opal-icon-envelope"></i></div>
                                    <div className="content">
                                        <span className="title text-uppercase">{translate('email')} :</span>
                                        <p className="description">info@cpr.am</p>
                                        <p className="description">support@cpr.am</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslate(ContactPage);