var module = {
    am: {
        locale: 'am-AM',
        messages: {
            en: 'Անգլերեն',
            am: 'Հայերեն',
            ru: 'Ռուսերեն',
            coming_soon: "ՄԵՐ ԿԱՅՔԷՋԸ ՇՈՒՏՈՎ",
            coming_soon_detail_1_1: 'Հարգելի՛ հաճախորդներ, բարի գալուստ ',
            coming_soon_detail_1_2: 'City Premium Realty',
            coming_soon_detail_1_3: ' անշարժ գույքի գործակալության պաշտոնական կայքէջ: Շուտով այստեղ կարող եք ստանալ ամբողջական տեղեկատվություն կառուցապատվող նորակառույց բնակելի շենքերի մասին, որոնց վաճառքի համար մենք ունենք բացառիկ իրավունք և այն իրականացնում ենք 0% միջնորդավճարով:',
            coming_soon_detail_2: 'Մեր գործընկերներն ունեն տարիների փորձ և որակի բարձր նշաձող, մեր համագործակցության արդյունքում դուք այստեղ կգտնեք Երևան քաղաքի լավագույն թաղամասերի ամենաորակյալ բնակելի շենքերի բնակարանները:',
            coming_soon_detail_3_1: '',
            coming_soon_detail_3_2: 'Առանց միջնորդավճարի ',
            coming_soon_detail_3_3: 'մեր մասնագետները ձեզ կտրամադրեն խորհրդատվություն, կօգնեն բնակարանի ամրագրման և գնման համար բոլոր անհրաժեշտ գործընթացներն անցնել սահուն և արագ:',
            coming_soon_detail_4: 'Այս պահին արդեն մեկնարկել է 8 հարկանի այս ինքնատիպ բնակելի շենքի կառուցապատումը, որն ունենալու է գերմանական Schindler ընկերության վերելակ, իտալական Profal ընկերության պատուհաններ, բարձրորակ դռներ և իտալական Mottura ընկերության դռան փական: Բնակելի համալիրն ունենալու է լողավազան, հանգստյան գոտի՝ իր տաղավարով, նաև 24 ժամյա անվտանգություն և այլն:',
            building_info: 'Բնակելի շենքը ՝ Արաբկիր 21, 26 շենք, Երևան',
            office_info: 'Վաճառքի գրասենյակ ՝ Լեոյի 1, 164, Երևան',
            building_end_date_info: 'Ծրագրի ավարտը նախատեսված է 2022 թվականի Սեպտեմբեր ամսին',
            contact_us_label: 'Մեզ հետ կարող եք կապ հաստատել զանգահարելով ՝',
            contact_us_phone: '+374 33 511-500',
            contact_us_phone_details: '(Viber, WhatsApp)',
            live_label_part1: 'Կառուցապատման ընթացքին կարող եք հետևել ուղիղ կապով ՝ հենց շինհրապարակից սեղմեք ',
            live_label_link: 'այս հղման վրա',
            live_label_part2: ':',
            live_link_soon: 'Կառուցապատման ընթացքին կարող եք հետևել ուղիղ կապով ՝ հենց շինհրապարակից ',
            live_link_soon_bold: 'ՇՈՒՏՈՎ',
            floor: 'Հարկ',
            available: 'Առկա է',
            sold: 'Վաճառված է',
            booked: 'Ամրագրված է',
            schedule_a_visit: 'Նշանակել այց',
            your_name: 'Ձեր անունը',
            phone_number: 'Հեռախոսի համարը',
            your_email: 'Ձեր էլ․ փոստը',
            how_can_we_help_you: 'Ինչպե՞ս կարող ենք ձեզ օգնել',
            submit: "Ուղարկել",
            square_areas: 'Ընդհանուր մակերես (մ²)',
            area: 'Մակերես (մ²)',
            parking: 'մեքենայի կայանատեղի',
            apartments: 'Բնակարաններ',
            green_areas: 'Կանաչապատ տարածքներ (մ²)',
            office_location_label: 'Գրասենյակի վայրը',
            office_location_info: 'Լեոյի 1, Երևան',
            contact_agent: 'Սպասարկող մասնագետ',
            operator_name: 'Անի Հովսեփյան',
            sales_agent: 'Վաճառքի մասնագետ',
            enquire: 'Հարցնել',
            are_you_interested: 'Ձեզ հետաքրքի՞ր է',
            its_time_to_discover: 'Ժամանակն է բացահայտելու',
            the_building: 'շենքը',
            all_rights_reserved: 'բոլոր իրավունքները պաշտպանված են',
            success_done: 'Հաջողությամբ կատարված է',
            soon_reply: 'Մեր մասնագետը շուտով կկապնվի ձեր հետ',
            details: 'Մանրամասն',
            m_square: 'մ²',
            meter: 'մ',
            building: 'Շենք',
            apartment_details: 'Բնակարանի մանրամասներ',
            select_apartment: 'Ընտրել բնակարան',
            question1: 'Որտե՞ղ է գտնվում շենքը',
            answer1: 'Շենքը գտնվում է Հրաչյա Քոչար փողոցի հարևանությամբ (բանջարանոցի կամուրջից ձախ, երրորդ փողոցով): Քարտեզին կարող եք ծանոթանալ ',
            answer1_link: 'այստեղ',
            question2: 'Ի՞նչ ճանապարհներով կարելի է մոտենալ շենքին:',
            answer2_part1: 'Տարբերակ 1 – Կարելի է մոտենալ Ազատության պողոտայով, Բաբայան փողոցով, Վահագն Դավթյան փողոցով ձախ՝ դեպի Արաբկիր 21 փողոց',
            answer2_part2: 'Տարբերակ 2-  Կարելի է մոտենալ Սարալանջի փողոցով` բարձրանալով դեպի Հրաչյա Քոչար փողոց Ռիգայի նրբանցքով՝ թեքվելով ձախ դեպի Արաբկիր 21 փողոց: ',
            answer2_part3: 'Տարբերակ 3 - Հրաչյա Քոչար փողոցով, բանջարանոցի կամրջով աջ երրորդ փողոցով ձախ դեպի Արաբկիր 21',
            question3: 'Քանի՞ հարկանի է շենքը',
            answer3: 'Շենքը 8 հարկանի է և բաղկացած է 2 մուտքից: Նախատեսվում է ստորգետնյա ավտոկայանատեղի, ընդհանուր՝ 23 ավտոկայանատեղիով',
            question4: 'Ինչպիսի՞ն է շենքի դիրքը, տեսարանները',
            answer4: 'Նայում է դեպի հյուսիս և հարավ, արևկող է, տեսարանը բաց է՝ դեպի Արարատը',
            question5: 'Ո՞ր կողմ են նայում բնակարանների պատուհանները',
            answer5: 'Շենքի մի կողմի պատուհանները նայում են դեպի Արարատը և Անտառային, իսկ մյուս կողմի պատուհանները նայում են դեպի Հրաչյա Քոչար փողոց:',
            question6: 'Ո՞վ է շենքի ճարտարապետը',
            answer6: 'Zevs Studio, Արամ Մուրադյան',
            question7: 'Ի՞նչպես է հանձնվում բնակարանը',
            answer7part1: 'Բնակարանը հանձնվելու է բարձրորակ արտաքին դուռ և պատուհաններ տեղադրված, սենյակների բաժանված, գաջապատված, հատակը հարթեցված,  Էլեկտրամոնտաժման աշխատանքներն արված վիճակում: Պատշգամբները հանձնվելու են պատրաստի վիճակում:',
            answer7part2: 'Շենքը երեսպատվելու է Անիի տուֆով, սև տուֆով, տրավերտինով և բազալտով: Այն ունենալու է լողավազան, հանգստյան գոտի, տաղավար, կանաչ գոտիներ և ստորգետնյա ավտոկայանատեղի:',
            question8: 'Ի՞նչքան է կազմելու ծառայության ամսական վճարը',
            answer8: 'Ծառայության ամսական վճարը կազմելու է 230 դրամ 1 քմ-ի համար: Այն ներառելու է աղբահանության, վերելակի սպասարկման, 24ժ անվտանգության, մաքրության և այգեպանի սպասարկումը:',
            question9: 'Ե՞րբ են սկսվել շինարարական աշխատանքները',
            answer9: 'Շինարարական աշխատանքները սկսվել են 2020 թվականին:',
            question10: 'Շենքը ե՞րբ է հանձնվելու շահագործման',
            answer10: 'Նախատեսված է շենքը հանձնել շահագործման 2022թ․-ի Սեպտեմբեր ամսին:',
            question11: 'Ի՞նչ արժի 1 քմ-ն',
            answer11_0: 'Արժեքը ըստ հարկերի տարբեր է՝',
            answer11_1: 'Հարկ 1՝ $1300',
            answer11_2: 'Հարկ 2՝ $1350',
            answer11_3: 'Հարկ 3՝ $1400',
            answer11_4: 'Հարկ 4՝ $1450',
            answer11_5: 'Հարկ 5՝ $1500',
            answer11_6: 'Հարկ 6՝ $1550',
            answer11_7: 'Հարկ 7՝ $1600',
            answer11_8: 'Հարկ 8՝ $1750',
            question12: 'Ի՞նչ արժի ավտոկայանատեղին',
            answer12: '$ 20 000',
            question13: 'Շենքի տարածքում կա՞ կոմերցիոն տարածք',
            answer13: 'Այո, կա 57քմ-անոց տարածք։ 1 քմ արժեքը $1550',
            question14: 'Ի՞նչ արժի գործակալության ծառայությունը',
            answer14_1: 'Մեր գործակալությունը ',
            answer14_bold: 'չի գանձում հավելյալ գումար',
            answer14_2: ' բնակարանի գնման գործարքից: Մենք իրականացնում ենք բնակարանի ամրագրման, գնման ամբողջ գործարքը 0% միջնորդավճարով: Անվճար է:',
            homeMain1_1: '',
            homeMain1_2: 'Արաբկիր Premium ',
            homeMain2: 'Քո նոր բնակարանը Երևանում մեզ մոտ է',
            homeMain3: 'Հիանալի տեսարան քո պատուհանից',
            aboutBuilding: 'Արաբկիր Premium',
            aboutBuildingInfo1: 'Արաբկիր 21 փողոցում գտնվող այս էլեգանտ շենքը  բաղկացած է երկու մուտքերից, որոնք ունեն բաց, լուսավոր և գեղեցիկ տեսարան դեպի Արարատը և քաղաքը:',
            aboutBuildingInfo2: 'Շենքը կառուցվում է բացառապես բարձրորակ նյութերով `  կարևորելով ամեն մանրուք բնակիչների հարմարավետության և ապահովության համար:  Շենքի յուրաքանչյուր մուտքն ունենալու է շվեյցարական Schindler ընկերության վերելակ,  Profal ընկերության պատուհաններ, բարձրորակ արտաքին դռներ և իտալական Mottura ընկերության դռան փական: Բնակելի համալիրն ունենալու է լողավազան, հանգստյան գոտի՝ իր տաղավարով, նաև 24 ժամյա անվտանգություն:  Ծառայությունների ամսական գումարը կկազմի 230 դրամ 1քմ համար:',
            aboutUs: 'Մեր մասին',
            aboutUsInfo1: 'City Premium Realty – ում կարող եք գտնել տեղեկատվություն կառուցապատվող նորակառույց բնակելի շենքերի մասին, որոնց վաճառքի համար մենք ունենք բացառիկ իրավունք և այն իրականացնում ենք 0% միջնորդավճարով:',
            aboutUsInfo2: 'Մեր գործընկերներն ունեն տարիների փորձ և որակի բարձր նշաձող, և մեր համագործակցության արդյունքում դուք այստեղ կգտնեք Երևան քաղաքի լավագույն թաղամասերի ամենաորակյալ բնակելի շենքերի բնակարանները:',
            aboutUsTitle2: 'Մեր ծառայությունները',
            aboutUs2Info1: '',
            aboutUs2InfoBold: 'Առանց միջնորդավճարի',
            aboutUs2Info2: ' մեր մասնագետները ձեզ կտրամադրեն խորհրդատվություն, կօգնեն բնակարանի ամրագրման և գնման համար բոլոր անհրաժեշտ գործընթացներն անցնել սահուն և արագ: ',
            albumZoomText1: 'Բացօթյա լողավազան հենց քո բակում',
            albumZoomText2: 'Հանգստյան հաճելի վայր` բուխարիով, ամառային խոհանոցով',
            albumZoomText3: 'Հարմարավետ ավտոկայանատեղի',
            albumZoomText4: 'Ամեն անկյունի որակը կարևոր է, իսկ դու ո՞ր հարկում ես ուզում բնակվել',
            albumZoomText5: 'Անիի տուֆ, սև տուֆ, բազալտ և տրավերտին',
            amenities_title: 'Շենքի առանձնահատկություններն ու ծառայությունները ',
            amenities_info: 'Ծառայությունների ամսական վճարը կազմում է 1 քմ-ն 230 դրամ',
            amenities_1: 'Լողավազան',
            amenities_2: 'Հանգստյան գոտի',
            amenities_3: 'Կանաչ տարածք',
            amenities_4: 'Ստորգետնյա ավտոկայանատեղի',
            amenities_5: 'Փակ անցակետ',
            amenities_6: '24ժ անվտանգություն',
            amenities_7: 'Մաքրություն ',
            amenities_8: 'Պատշգամբներ լուսավոր, բաց տեսարաններ',
            services_title: 'Մեր ծառայությունները',
            services_1: 'Բնակարանի դիզայն',
            services_1_info: 'Բնակարանների դիզայնի անհատական պատվերների ստացում',
            services_2: 'Մասնագիտական անվճար խորհրդատվություն',
            services_2_info: '0 % միջնորդավճարով մեր գրասենյակի վաճառքի մասնագետները տրամադրում են խորհրդատվություն և ձեզ ուղեկցում ամրագրման, գնման բոլոր գործընթացներում',
            services_3: 'Պայմանագրերի կազմում',
            services_3_info: 'City Premium Realty – ն չի գանձում միջնորդավճար բնակարանի ամրագրման կամ գնման որևէ գործարքից, որևէ փուլում: Բնակարանի ամրագրման և գնման ողջ գործարքը կատարում ենք 0% միջնորդավճարով: Գնորդի իրավունքներն ու պարտավորություններն ամրագրվում են պայմանագրով, որը կազմում են մեր մասնագետները գնորդի հետ համաձայնեցված: ',
            slider1Title: 'Մանրամասներ շենքի մասին',
            slider1Text: 'Շենքը կառուցված է բարձրորակ նյութերով և հագեցած է բարձրակարգ և վստահելի սարքավորումներով: Մասնավորապես, շենքի պատի կտրվածքը՝ տես կից նկարում։\n' +
                ' Շենքը հագեցած է ժամանակակից ամենահուսալի և անվտանգ համակարգերով : Առկա է հակահրդեհային համակարգ, տեսահսկում և 9 բալ սեյսմակայունություն:\n' +
                'Լողավազանի չափերը` 4.2 x 10\n' +
                'Տաղավարի չափերը` 5000 x 10000\n' +
                'Շենքը ունի արտաքին լուսավորություն\n' +
                'Մուտքերը ջեռուցվող, օդափոխվող\n' +
                'Գազ, ջուր, լույսը ՝ անհատական\n',
            slider2Title: 'Պատուհանները Profal ընկերության',
            slider2Text: '  Alumil ընկերության #SMARTIAS560 #սլայդ_համակարգով դռներ, որոնք կապահովեն Ձեր տան էներգաարդյունավետությունն ու անվտանգությունը, և կբավարարեն ճարտարապետական նախագծման ամենախիստ պահանջները:\n' +
                'SMARTIA S560 սլայդային համակարգը գերադասելի է սենյակը տեսանելիորեն ընդարձակ դարձնելու համար՝ շնորհիվ իր դիզայնի և տեխնիկական հատկանիշների:\n' +
                '➊ Առավելագույն բնական լուսավորություն՝ շնորհիվ պատուհանների մեծ շրջանակների\n' +
                '➋ Օգտագործման դյուրինություն\n' +
                '➌ Բարձր գործունակություն, անգամ եղանակային խիստ պայմաններում\n' +
                '➍ Բարձր էներգաարդյունավետություն\n' +
                '➎ Անվտանգության ապահովում\n' +
                '➏ Ձայնամեկուսացման բարձր մակարդակ',
            slider3Title: 'Schindler վերելակներ',
            slider3Text: 'Շենքը ունի շվեյցարական  բարձրորակ և անվտանգ  2 վերելակ։ Վերելակի չափերը՝ 630կգ, 8 անձի համար, խցիկի չափը 120x140',
            slider4Title: 'Բարձրորակ դռներ',
            slider4Text: 'Բնակարանի արտաքին դուռը երկաթյա բարձրորակ դուռ է իտալական mottura ընկերության փականով, որը ապահովում է ձեր բնակարանի անվտանգությունը:',
            faq: 'Հաճախակի տրվող հարցեր',
            our_partners: 'Մեր գործընկերները',
            live_title_1: 'Շինարարական աշխատանքներն ',
            live_title_2: 'հենց հիմա',
            live_text_1: 'Այստեղ կարող եք հետևել շինարարական աշխատանքներին և ընթացքին հենց շին հրապարակից՝ ',
            live_text_bold: 'ուղիղ միացումով:',
            live_text_2: 'Մեզ համար ձեր վստահությունն առաջնային է, ուղիղ միացումով հետևեք աշխատանքներին ցանկացած պահի:',
            rio_desc: 'Rio Mall արևտրի կենտրոն (Ընդամենը 300մ հեռավորության վրա գտնվում է առևտրի կենտրոն Rio Mall-ը, որտեղ կգտնեք հագուստի խանութներ, ինչպես նաև սննդի և ժամանցի վայրեր)',
            gym_desc: 'Gold´s Gym (650մ հեռավորության վրա գտնվում է Gold´s Gym սպորտային սրահը, որտեղ կարող եք մարզվել և օգտվել լողավազանից)',
            school_desc: 'Ռուբեն Սևակի անվան հանրակրթական դպրոց (դպրոցը գտնվում է ընդամենը 280մ հեռավորության վրա)',
            supermarket_desc: 'Evrika սուպերմարկետ',
            kindergarten_desc: 'Համար 26  մանկապարտեզ',
            park_desc: 'Komitas Park',
            globbing_desc: 'Globbing',
            more_info: 'Տեսնել ավելին',
            call: 'Զանգահարել',
            rooms: 'Սենյակներ',
            ceiling_height: 'Առաստաղի բարձրություն',
            status: 'Կարգավիճակ',
            price: 'Գին',
            arabkir: 'Արաբկիր',
            details_menu: 'Մանրամասներ',
            choose_apartment: 'Ընտրել բնակարան',
            live: 'Live',
            about_us: 'Մեր մասին',
            contact: 'Կապ',
            faq_menu: 'Հաճախ տրվող հարցեր',
            A: 'Ա',
            B: 'Բ',
            floors: 'հարկ',
            evrika: 'EVRIKA ՍՈՒՊԵՐՄԱՐԿԵՏ',
            rio: 'Rio Mall',
            goldsgym: 'Gold\'s Gym',
            school: '151 դպրոց',
            mankapartez: 'Համար 26  մանկապարտեզ',
            park: 'Կոմիտասի անվան այգի',
            globbing: 'Globbing',
            watch_live1: 'Դիտել ուղիղ եթեր 1',
            watch_live2: 'Դիտել ուղիղ եթեր 2',
            watch_live3: 'Դիտել ուղիղ եթեր 3',
            phone: 'Հեռախոսահամար',
            address: 'Հասցե',
            email: 'էլ․ փոստ',
            get_in_touch: 'Կապ մեզ հետ',
            your_message: 'Ձեր նամակը',
            leave_message: 'Ուղարկել նամակ',
            contact_us: 'ԿԱՊ ՄԵԶ ՀԵՏ',
            neighborhoods: 'Շենքի Հարևանությամբ',
            gallery: 'Տեսադարան',
            equal_dram: '*Համարժեք ՀՀ դրամ',
            layout_photos: 'Հատակագծերի նկարներ',
            building_photos: 'Շենքի նկարներ',
            aboutBaghramyanBuilding: 'Բաղրամյան Deluxe',
            aboutBaghramyanBuildingInfo1: 'Այս շենքում կարող եք ապրել և վայելել ձեր կեցությունը հատուկ կարգավիճակով՝ բարեկեցիկ, հարմարավետ և շքեղ: Ապրելու եք հենց Երևանի սրտում, ամենաակտիվ նաև ամենահանգիստ հատվածում:',
            aboutBaghramyanBuildingInfo2: 'Շուտով այս կայքում ներկայացված կլինի նախագծի ամբողջական նկարագիրը և հատուկ ծառայությունները միայն բնակիչների համար՝',
            new_amenities_1: 'լողավազան',
            new_amenities_2: 'շոգեբաղնիք',
            new_amenities_3: 'սպորտ դահլիճ',
            new_amenities_4: 'chill out, բար - սրահ',
            new_amenities_5: 'barbecue',
            new_amenities_6: 'գինու մառան',
            new_amenities_7: '24ժ անվտանգություն',
            new_amenities_8: '24ժ ընդունարան',

        }
    },
    en: {
        locale: 'en-US',
        messages: {
            en: 'English',
            am: 'Armenian',
            ru: 'Russian',
            coming_soon: "OUR WEBSITE COMING SOON",
            coming_soon_detail_1_1: 'Dear customers, welcome to the official website of the real estate agency ',
            coming_soon_detail_1_2: 'City Premium Realty',
            coming_soon_detail_1_3: '. Soon here you can get complete information about the new – built residential buildings, for which we have exclusive right to sell with a 0% commission.  ',
            coming_soon_detail_2: 'Our partners have years of experience and high-quality standards, as a result of our cooperation you will find here the best new – built properties in the most important districts of Yerevan.',
            coming_soon_detail_3_1: 'Our specialists will provide you with ',
            coming_soon_detail_3_2: 'free of charge',
            coming_soon_detail_3_3: ' consultation, help you go through all the necessary processes of booking and buying an apartment smoothly and quickly.',
            coming_soon_detail_4: ' The construction of this unique 8-storey residential building has already started, which will have an elevator from the German company Schindler, windows from the Italian company Profal, high-quality doors and a door lock from the Italian company Mottura.\n' +
                ' The residential complex will have a swimming pool, a recreation area with its pavilion, 24-hour security, etc.\n',
            building_info: 'Residential building: Arabkir 21/26 area, Yerevan.',
            office_info: 'Sales office: Leo 1, Yerevan.',
            building_end_date_info: 'The project is scheduled for completion in September 2022.',
            contact_us_label: 'You can contact us by calling',
            contact_us_phone: '+374 33 511-500',
            live_label_part1: 'You can follow the construction process directly by clicking on ',
            live_label_link: 'this link',
            live_label_part2: ' right from the construction site.',
            live_link_soon: 'You can follow the construction process directly from the construction site ',
            live_link_soon_bold: 'SOON',
            floor: 'Floor',
            available: 'Available',
            sold: 'Sold',
            booked: 'Booked',
            schedule_a_visit: 'Schedule a visit',
            your_name: 'Your name',
            phone_number: 'Phone Number',
            your_email: 'Your e-mail',
            how_can_we_help_you: 'How can we help you?',
            submit: "Submit",
            square_areas: 'square areas (m²)',
            area: 'Areas (m²)',
            parking: 'car parking',
            apartments: 'apartments',
            green_areas: 'green areas (m²)',
            office_location_label: 'OFFICE LOCATION',
            office_location_info: 'Leo 1, Yerevan',
            contact_agent: 'CONTACT AGENT',
            operator_name: 'Ani Hovsepyan',
            sales_agent: 'SALES AGENT',
            enquire: 'ENQUIRE',
            are_you_interested: 'ARE YOU INTERESTED',
            its_time_to_discover: "IT'S TIME TO DISCOVER",
            the_building: 'THE BUILDING',
            all_rights_reserved: 'all rights reserved',
            success_done: 'Successfully done',
            soon_reply: 'Our agent will contact you soon',
            details: 'Details',
            m_square: 'm²',
            meter: 'm',
            building: 'Building',
            apartment_details: 'Apartment Details',
            select_apartment: 'Select apartment',
            question1: 'Where is the building located?',
            answer1: 'The building is located near Hrachya Kochar street (the third street turning to the left from the bridge). See the map ',
            answer1_link: 'here.',
            question2: 'What are the ways to get to the building?',
            answer2_part1: 'Option 1 – One of the ways to get to Arabkir 21 street is via Azatutyun Avenue, Babayan Street, turning to the left, from Vahagn Davtyan street.',
            answer2_part2: 'Option 2 – Another way to get to Arabkir 21 street is via Saralanj Street, going up to Hrachya Kochar Street, Riga alley, turning right to enter Arabkir 21.',
            answer2_part3: 'Option 3 – Via Hrachya Kochar Street, vegetable garden bridge, right third street, left to Arabkir 21.',
            question3: 'How many floors up is the building?',
            answer3: 'The building has 8 floors and consists of 2 entrances. Underground parking is provided with a total of 23 parking spaces.',
            question4: 'What is the location of the building, the views?',
            answer4: 'It looks to the north-south, it is sunny side, the view opens to Mount Ararat.',
            question5: 'Which side do the windows of the apartments look?',
            answer5: 'The windows on one side face Mount Ararat and Antarain, the other side face Hrachya Kochar Street.',
            question6: 'Who is the architect of the building?',
            answer6: 'Zevs Studio, Aram Muradyan',
            question7: 'How is the apartment handed over',
            answer7part1: 'The apartment will be handed over with high quality external door and windows installed, rooms are divided, plastered, the floor is leveled, the electrical installation works are done. The balconies will be handed over ready. ',
            answer7part2: 'The building will be covered with Ani tuff, black tuff, travetine and basalt․ It will have a swimming pool, recreation area, pavilion, green area and underground parking.',
            question8: 'How much will the monthly service fee be?',
            answer8: 'The monthly service fee will be 230AMD per square meter. It includes the following services: garbage collection, elevator service, 24h security, cleaning and gardening.',
            question9: 'When did the construction work start?',
            answer9: 'The construction work began in 2020. ',
            question10: 'When will the building be commissioned?',
            answer10: 'The building will be commissioned by September of 2022. ',
            question11: 'How much is 1 square meter?',
            answer11_0: 'Prices vary per floor:',
            answer11_1: '1 floor: $1300',
            answer11_2: '2 floor: $1350',
            answer11_3: '3 floor: $1400',
            answer11_4: '4 floor: $1450',
            answer11_5: '5 floor: $1500',
            answer11_6: '6 floor: $1550',
            answer11_7: '7 floor: $1600',
            answer11_8: '8 floor: $1750',
            question12: 'How much is the parking space?',
            answer12: '$ 20 000',
            question13: 'Is there a commercial area in the building?',
            answer13: 'Yes, there is a commercial area of 57sq at $ 1550 per meter square.',
            question14: 'How much is the agency brokerage fee?',
            answer14_1: 'Our agency ',
            answer14_bold: 'does not charge any fee',
            answer14_2: ' during the buying and selling process: 0% commission.',
            homeMain1_1: '',
            homeMain1_2: 'Arabkir Premium',
            aboutBuilding: 'Arabkir Premium',
            aboutBuildingInfo1: 'This elegant building is located on Arabkir street number 21 and consists of two entrances․ It has an open bright window view of the city and Mount Ararat.',
            aboutBuildingInfo2: 'The building is built with exclusively high-quality materials, using every detail for the comfort and safety of the resident. Each entrance to the building will have an elevator made by a Swiss company called Schindler, windows by Profal, high quality exterior doors and an Italian door lock by Mottura. The residential building will have a swimming pool, a pavilion with summer kitchen, as well as 24-hour surveillance and security guard. The monthly fee for the services will only be 230AMD per square meter.',
            aboutUs: 'About Us',
            aboutUsInfo1: 'At City Premium Realty you can find information about new-built properties in Yerevan: our agency has the exclusive right to sell them with a 0% commission.',
            aboutUsInfo2: 'Our partners have years of experience in the industry and high-quality standards: As a result of our cooperation, we now can offer our customers properties in the best districts of Yerevan.',
            aboutUsTitle2: 'Our services',
            aboutUs2Info1: 'Our Specialists will provide you with ',
            aboutUs2InfoBold: 'free of charge',
            aboutUs2Info2: ' consultation, help you go through all the necessary processes of booking and buying an apartment smoothly and quickly.',
            albumZoomText1: 'Outdoor swimming pool right in your backyard',
            albumZoomText2: 'Nice pavilion with fireplace & summer kitchen.',
            albumZoomText3: 'Your car in the comfortable underground parking',
            albumZoomText4: 'Quality matters in everything, what floor do you want to live on?',
            albumZoomText5: 'Ani tuff, black tuff, basalt and travertine',
            amenities_title: 'Features and services of the building',
            amenities_info: 'The monthly fee for the services will only be 230AMD per square meter',
            amenities_1: 'Swimming pool',
            amenities_2: 'Summer Pavilion',
            amenities_3: 'Underground parking',
            amenities_4: 'Closed checkpoint',
            amenities_5: 'Security Guard',
            amenities_6: 'Cleaning service',
            amenities_7: 'Green area',
            amenities_8: 'Balconies and open views',
            services_title: 'Our Services',
            services_1: 'Interior Design',
            services_1_info: 'Interior Design for extra charge',
            services_2: 'Professional consultation for free',
            services_2_info: 'Our Specialists will provide you with free of charge consultation, help you go through all the necessary processes of booking and buying an apartment smoothly and quickly.',
            services_3: 'Making the Real Estate Contract',
            services_3_info: 'At City Premium Realty we do not charge a commission for your property purchase deal. The whole house - buying process is free of charge.  The agency doesn’t charge a commission. Our specialists make sure the terms and conditions of the agreement are accurate of the parts.',
            slider1Title: 'Building Details',
            slider1Text: 'This building is built of high-quality materials and is very well equipped. In particular, have a look on the structure of the walls (see the picture below)\n' +
                'The building is fully equipped with the most reliable and modern systems. There is a fire-fighting system, video surveillance and is seismic resistant (9 magnitude).\n' +
                'Swimming Pool size: 4.2 x 10\n' +
                'Summer Pavilion size: 5000 x 10000 (including WC in the area)\n' +
                'Exterior lighting\n' +
                'Heated and ventilated entrances\n' +
                'Individual gas, water and electricity\n',
            slider2Title: 'Windows from Profal',
            slider2Text: 'SMARTIA S560 is a remarkably flexible and robust thermally insulated system for lift & slide and regular sliding doors, that uses the same profiles for both solutions. It provides high thermal performance, robust constructions and is ideal for large openings.\n' +
                '➊ Maximum natural light due to large window frames\n' +
                '➋ Easy to use\n' +
                '➌ High efficiency in any weather condition\n' +
                '➍ High energy efficiency\n' +
                '➎ Hight level of sound insulation\n',
            slider3Title: 'Schindler elevators',
            slider3Text: 'The building has two high quality Swiss elevators: Elevator cabin size: 120x140, 630kg, max. 8 persons',
            slider4Title: 'High quality doors',
            slider4Text: 'The exterior door of the apartment is a high-quality iron door with a door lock made by Italian Company Mottura, which ensures the security of the apartment.',
            faq: 'Frequently asked questions',
            our_partners: 'Our Partners',
            live_title_1: 'Live Streaming ',
            live_title_2: '',
            live_text_1: 'Here you can follow the construction work right from the construction site with a direct connection. Follow the process ',
            live_text_bold: 'any time.',
            live_text_2: '',
            rio_desc: '',
            gym_desc: '',
            school_desc: '',
            more_info: 'See more',
            call: 'Call us',
            rooms: 'Rooms',
            ceiling_height: 'Ceiling Height',
            status: 'Status',
            price: 'Price',
            arabkir: 'Arabkir',
            details_menu: 'Details',
            choose_apartment: 'Choose Apartment',
            live: 'Live',
            about_us: 'About Us',
            contact: 'Contact',
            faq_menu: 'FAQ',
            A: 'A',
            B: 'B',
            floors: 'floors',
            evrika: 'EVRIKA SUPERMARKET',
            rio: 'Rio Mall',
            goldsgym: 'Gold\'s Gym',
            school: '151 School',
            mankapartez: 'Number 26 kindergarten',
            park: 'Komitas Park',
            globbing: 'Globbing',
            watch_live1: 'Watch Live 1',
            watch_live2: 'Watch Live 2',
            watch_live3: 'Watch Live 3',
            phone: 'Phone',
            address: 'Address',
            email: 'Email',
            get_in_touch: 'Get In Touch',
            your_message: 'Your Message',
            leave_message: 'Leave a message',
            contact_us: 'Contact Us',
            neighborhoods: 'APARTMENT NEIGHBORHOODS',
            gallery: 'Gallery',
            equal_dram: '*Equivalent AMD',
            park_desc: 'Komitas Park',
            layout_photos: 'Layout Photos',
            building_photos: 'Building Photos',
            aboutBaghramyanBuilding: 'Baghramyan Deluxe',
            aboutBaghramyanBuildingInfo1: 'Here, yօu will enjoy your residence in a special status - comfortable and luxurious life style. You will live in the heart of Yerevan, in the most active at the same time in the quietest part.',
            aboutBaghramyanBuildingInfo2: 'The full description of the project will be presented on this site soon.',
            new_amenities_1: 'Swimming pool',
            new_amenities_2: 'Sauna',
            new_amenities_3: 'Gym',
            new_amenities_4: 'Chill out bar',
            new_amenities_5: 'Barbecue',
            new_amenities_6: 'Wine cellar',
            new_amenities_7: '24 hour security',
            new_amenities_8: '24 hour reception',
        }
    },
    ru: {
        locale: 'ru-RU',
        messages: {
            en: 'Английский',
            am: 'Армянский',
            ru: 'Русский',
            coming_soon: "НАШ ВЕБ-САЙТ СКОРО БУДЕТ",
            coming_soon_detail_1_1: 'Уважаемые покупатели, добро пожаловать на официальный сайт агентства недвижимости ',
            coming_soon_detail_1_2: 'City Premium Realty',
            coming_soon_detail_1_3: '. Вскоре вы сможете получить полную информацию o новостройках, на продажу которых мы имеем исключительное право с комиссией 0%.',
            coming_soon_detail_2: 'Наши партнеры имеют многолетний опыт работы высокий стандарт качества, в результате нашего сотрудничества вы найдете здесь квартиры высочайшего качества в жилых домах лучших районов Еревана.',
            coming_soon_detail_3_1: '',
            coming_soon_detail_3_2: 'Наши специалисты бесплатно',
            coming_soon_detail_3_3: ' проконсультируют вас, помогут быстро и без проблем пройти все необходимые процессы для бронирования и покупки квартиры.',
            coming_soon_detail_4: 'Строительство этого уникального 8-этажного жилого дома уже в процессе. В здании будет установлен лифт немецкой компании Schindler, окна итальянской компании Profal, высококачественные двери и замки итальянского производства фирмы Mottura. В жилом комплексе проектирован бассейн, зона отдыха со своим павильоном, круглосуточная охрана и т. д.',
            building_info: 'Жилой дом: ул. Арабкир 21, дом 26, Ереван.',
            office_info: 'Офис продаж: Лео 1д, 164, Ереван.',
            building_end_date_info: 'Завершение проекта запланировано на сентябрь 2022 года.',
            contact_us_label: 'Вы можете связаться с нами по телефону',
            contact_us_phone: '+374 33 511-500',
            live_label_part1: 'Вы можете следить за процессом строительства также ',
            live_label_link: 'напрямую',
            live_label_part2: ', т.е. перейдя по данной ссылке, вы сможете онлайн, прямо со стройплощадки следить за процессом.',
            live_link_soon: 'Вы сможете онлайн, прямо со стройплощадки следить за процессом',
            live_link_soon_bold: 'СКОРО',
            floor: 'Этаж',
            available: 'Доступен',
            sold: 'Продано',
            booked: 'Забронировано',
            schedule_a_visit: 'Назначить визит',
            your_name: 'Ваше имя',
            phone_number: 'Номер телефона',
            your_email: 'Ваша эл. почта',
            how_can_we_help_you: 'Как мы можем вам помочь?',
            submit: "Отправить",
            square_areas: 'ОБЩАЯ ПЛОЩАДЬ (м²)',
            area: 'Площадь (м²)',
            parking: 'Стояночных мест',
            apartments: 'Квартир',
            green_areas: 'зеленая зона (м²)',
            office_location_label: 'РАСПОЛОЖЕНИЕ ОФИСА',
            office_location_info: 'Лео 1, Ереван',
            contact_agent: 'агент по обслуживанию',
            operator_name: 'Ани Овсепян',
            sales_agent: 'АГЕНТ ПО ПРОДАЖАМ',
            enquire: 'СПРОСИТЬ',
            are_you_interested: 'ВАМ ИНТЕРЕСНО',
            its_time_to_discover: 'ПРИШЛО ВРЕМЯ',
            the_building: 'ОСМОТРЕТЬ ЗДАНИЕ',
            all_rights_reserved: 'все права защищены',
            success_done: 'Успешно сделано',
            soon_reply: 'Наш специалист свяжется с вами в ближайшее время',
            details: 'Детали',
            m_square: 'м²',
            meter: 'м',
            building: 'Здание',
            apartment_details: 'Детали квартиры',
            select_apartment: 'Выбрать квартиру',
            question1: 'Где находится здание?',
            answer1: 'Здание находится рядом с улицей Грачя Кочара (на лево от моста Банджараноца по третей улице): Можете ознакомится с картой ',
            answer1_link: 'тут.',
            question2: 'Какими путями можно дойти до здания?',
            answer2_part1: '1 вариант – Можно дойти с улицы Азатутюн по улицам Бабаяна и Ваагн Давтяна, поворачивая на лево на 21-ую улицу Арабкира.',
            answer2_part2: '2 вариант - Можно дойти с улицы Сараландж, поднимаясь по направлению улицы Грачя Кочара, по переулку Риги сворачивая на лево на 21-ую улицу Арабкира.',
            answer2_part3: '3 вариант – по улице Грачя Кочара, по мосту Банджараноца с третей улицы на лево на 21-ую улицу Арабкира.',
            question3: 'Сколько этажей имеет здание?',
            answer3: 'Комплекс состоит из 8 этажей и 2 корпусов. Предусмотрено подземная стоянка, общее число стояночных мест 23.',
            question4: 'Как распложен комплекс? Какая панорама?',
            answer4: 'Смотрит на север и юг, обращён к солнцу, имеет открытый вид на гору Арарат.',
            question5: 'В какую сторону смотрят окна квартир?',
            answer5: 'Окна одной стороны комплекса смотрят на Арарат и улицу Антараин, а окна другой стороны – на улицу Грачя Кочара.',
            question6: 'Кто архитектор здания?',
            answer6: 'Арам Мурадян, Zevs Studio',
            question7: 'Как сдается квартира?',
            answer7part1: 'Квартира будет сдана с высококачественными внешними дверями и окнами, разделенный на комнаты, оштукатуренный, с ровными полом, с завершёнными электромонтажными работами. Балконы будут сданы в готовом состоянии. ',
            answer7part2: 'Здание будет облицовано туфом Ани, черным туфом, травертином и базальтом. Оно будет иметь бассейн, зону отдыха, беседку, зеленные зоны и подземную парковку.',
            question8: 'Сколько составит ежемесячная оплата за услугу?',
            answer8: 'Ежемесячная оплата за услугу составит 230 драм за 1 м². В нее входят вывоз мусора, обслуживание лифта, 24 часовая охрана, отчистка территории и услуги садовника.',
            question9: 'Когда начались строительные работы?',
            answer9: 'Строительные работы начались в 2020 году. ',
            question10: 'Когда здание будет сдана на эксплуатацию',
            answer10: 'Предназначено сдать здание в сентябре 2022 года. ',
            question11: 'Сколько стоит 1 м²?',
            answer11_0: 'Стоимость меняется зависимо от этажа ',
            answer11_1: '',
            answer11_2: '',
            answer11_3: '',
            answer11_4: '',
            answer11_5: '',
            answer11_6: '',
            answer11_7: '',
            answer11_8: '',
            question12: 'Сколько стоит стояночное место?',
            answer12: '$ 20 000',
            question13: 'Есть ли кооперационный участок на территории комплекса?',
            answer13: 'Да есть участок с площадью 57 м², Стоимость 1   м² - 1550$.',
            question14: 'Сколько стоит услуга агентства?',
            answer14_1: 'Наше агентство ',
            answer14_bold: 'не взимает дополнительные платы ',
            answer14_2: ' с продаж квартир. Мы осуществляем вес процесс бронировки и купли квартир с 0% комиссионными. Услуга бесплатная.',
            homeMain1_1: '',
            homeMain1_2: 'Арабкир Премиум',
            aboutBuilding: 'Арабкир Премиум',
            aboutBuildingInfo1: 'Элитный комплекс, расположенный на 21-ой улице Арабкира состоит из двух корпусов, которые имеют открытий, светлый и живописный вид на город и гору Арарат.',
            aboutBuildingInfo2: 'Здание строится исключительно из высококачественных материалов – выделяя каждую мелочь для комфорта и безопасности жителей.  Каждый корпус будет иметь лифт швейцарской кампании Schindler, окна фирмы Profal, высококачественные внешние двери и замки итальянской компании Mottura. Жилой комплекс будет иметь бассейн, зону отдыха с беседкой, а также круглосуточную охрану. Ежемесячная оплата за услугу составит 230 драм за 1 м².',
            aboutUs: 'О нас',
            aboutUsInfo1: 'У нас можете найти информацию о застройке жилой недвижимости для продажи которой мы имеем эксклюзивное право и продаем без комиссионных.',
            aboutUsInfo2: 'Наши партнёры имеют многолетний опыт и высокую планку качества, и в результате нашего сотрудничества Вы здесь найдёте квартиры самых качественных жилых комплексов, которые находятся в лучших районах Еревана..',
            aboutUsTitle2: 'Наши услуги',
            aboutUs2Info1: 'Наши специалисты окажут Вам консультацию, помогут легко и бистро пройти все нужные процессы при бронировании и покупке квартиры  ',
            aboutUs2InfoBold: 'без каких-либо комиссионных.',
            aboutUs2Info2: '',
            albumZoomText1: 'Открытый бассейн во дворе',
            albumZoomText2: 'Приятная зона отдыха с камином и летной кухней.',
            albumZoomText3: 'Удобная парковка',
            albumZoomText4: 'Качество любого уголка важна, а на каком этаже ты хочешь жить?',
            albumZoomText5: 'Ани туф, черный туф, базальт и травертин',
            amenities_title: 'Уникальности и услуги комплекса ',
            amenities_info: 'Ежемесячная оплата за услугу составит 230 драм за 1 м².',
            amenities_1: 'Бассейн',
            amenities_2: 'Зона отдыха',
            amenities_3: 'Зеленная зона',
            amenities_4: 'Подземная парковка',
            amenities_5: 'Закрытый пропускной пункт',
            amenities_6: 'Круглосуточная охрана',
            amenities_7: 'Отчистка территории',
            amenities_8: 'Балконы с живописными видами',
            services_title: 'Наши услуги',
            services_1: 'Дизайн квартир',
            services_1_info: 'Дизайн квартир по личным заказам. ',
            services_2: 'Бесплатная профессиональная консультация',
            services_2_info: 'Наши специалисты по продажам без комиссионных оказывают консультацию и сопровождают Вас во всех процессах бронировки и купли квартир.',
            services_3: 'Составление договоров',
            services_3_info: 'City Premium Realty не взымает комиссионные с каких либо сделок бронировки и купли квартир на любом этапе. Всю сделку бронировки и купли квартир мы делаем с 0% комиссионными. Права и обязанности покупателя фиксируются договором, которые составляют наши специалисты, согласовывая с покупателем.',
            slider1Title: 'Подробности о здании',
            slider1Text: 'Здание построено из высококачественных материалов, оно оснащено лучшими и доверенными оборудованиями. В частности, Вы можете увидит разрез стены здания (картинка слева): \n' +
                'Здание оснащено самими безопасными и надежными системами. Есть противопожарная система, видеонаблюдение и 9 бальная сейсмостойкость.\n' +
                'Размер бассейна: 4.2 x 10\n' +
                'Размер беседки: 5000 x 10000\n' +
                'Здание имеет внешнее освещение\n' +
                'Подъезди отапливаются проветриваются\n' +
                'Газ, свет, вода: индивидуальная \n',
            slider2Title: 'Окна от кампании Profal',
            slider2Text: 'Дверцы SMARTIA S560 компании Alumil представляет собой надежную подъемно-раздвижную систему, обеспечивающую высокий уровень теплоизоляции и гибкость в переработке и удовлетворят самые строгие требования архитектурного проектирования.\n' +
                '➊ Максимальное естественное освещение\n' +
                '➋ Простота использования\n' +
                '➌ Гладкое и легкое скольжение даже в суровых погодных условиях\n' +
                '➍ Высокое энергоэффективность\n' +
                '➎ Обеспечение безопасности\n' +
                '➎ Высокая шумоизоляция\n',
            slider3Title: 'Лифты компании SCHINDLER',
            slider3Text: 'Комплекс обслуживается двумя высококачественными и безопасными швейцарскими лифтами. \n Размеры лифта: 630кг, для 8 пассажиров, размер кабины 120x140',
            slider4Title: 'Высококачественные двери',
            slider4Text: 'Входная дверь квартиры представляет собой качественную железную дверь с замком итальянской фирмы Mottura, которая обеспечивает безопасность Вашей квартиры.',
            faq: 'ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ',
            our_partners: 'Наши партнёры',
            live_title_1: 'Прямая трансляция ',
            live_title_2: '',
            live_text_1: 'Здесь вы можете следить за ходом строительных работ прямо со строительной площадки с прямой связью. Следите за процессом ',
            live_text_bold: 'в любое время. ',
            live_text_2: '',
            rio_desc: '',
            gym_desc: '',
            school_desc: '',
            more_info: 'Подробности',
            call: 'Звонить',
            rooms: 'Комнаты',
            ceiling_height: 'Высота потолка',
            status: 'Статус',
            price: 'Цена',
            arabkir: 'Арабкир',
            details_menu: 'Подробности',
            choose_apartment: 'Выбрать квартиру',
            live: 'Прямой эфир',
            about_us: 'О нас',
            contact: 'Контакты',
            faq_menu: 'ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ',
            A: 'А',
            B: 'Б',
            floors: 'Этажи',
            evrika: 'EVRIKA супермаркет',
            rio: 'Rio Mall',
            goldsgym: 'Gold\'s Gym',
            school: '151 школа',
            mankapartez: 'Номер 26 детский сад',
            park: 'Komitas парк',
            globbing: 'Globbing',
            watch_live1: 'Смотреть прямой эфир 1',
            watch_live2: 'Смотреть прямой эфир 2',
            watch_live3: 'Смотреть прямой эфир 3',
            phone: 'Телефон',
            address: 'Адрес',
            email: 'Эл. Почта',
            get_in_touch: 'Задавайте Вопрос',
            your_message: 'Ваше сообщение',
            leave_message: 'Оставить сообщение',
            contact_us: 'Связаться с нами',
            neighborhoods: 'Недалеко расположены',
            gallery: 'Галерея',
            equal_dram: '*Эквивалентный АМД',
            park_desc: 'Комитас парк',
            layout_photos: 'Layout Photos',
            building_photos: 'Building Photos',
            aboutBaghramyanBuilding: 'Баграмян Делюкс',
            aboutBaghramyanBuildingInfo1: 'Вы можете жить в этом здании и наслаждаться пребыванием в особом статусе – благополучном, комфортном и роскошном. Вы будете жить в самом сердце Еревана, в самой активной, и в тоже время в самой тихой части.',
            aboutBaghramyanBuildingInfo2: 'Полное описание проекта и специальные услуги только для резидентов скоро будет представлено на данном сайте:',
            new_amenities_1: 'Бассейн',
            new_amenities_2: 'Баня',
            new_amenities_3: 'Тренажерный зал',
            new_amenities_4: 'Зона отдыха, барный зал',
            new_amenities_5: 'Зона для шашлыка',
            new_amenities_6: 'Винный погреб',
            new_amenities_7: '24 часовая охрана',
            new_amenities_8: 'Круглосуточный ресепшн',
        }
    },
};

export default module;