import React, {Component} from 'react';
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import Helmet from 'react-helmet';
import './live.scss';
import OfficeSpaceSection from "../../sections/office-space/office-space";

class Live extends Component {
    render() {
        const opts = {
            height: '360vw',
            width: '640vw',
            playerVars: {
                autoplay: 1,
            },
        };
        return (
            <div className={`contact-page-wrap`}>
                <Helmet>
                    <title>City Premium Realty | Live </title>
                </Helmet>
                <OfficeSpaceSection src={`${process.env.PUBLIC_URL}/assets/images/general/construction.jpg`}/>
            </div>
        )
    }
}

export default Live