import React, {Component} from 'react';
import "react-image-gallery/styles/css/image-gallery.css";

import {withTranslate} from "react-redux-multilingual";
// import ImageGallery from 'react-image-gallery';
import Heading from "../../common/heading/heading";

class GalleryNew extends Component {
    state = {
        isOpen: false,
        pics: [
            {
                original: "/assets/images/gallery/1.jpg",
                thumbnail: "/assets/images/gallery/1.jpg"
            },
            {
                original: "/assets/images/gallery/2.jpg",
                thumbnail: "/assets/images/gallery/2.jpg"
            },
            {
                original: "/assets/images/gallery/3.jpg",
                thumbnail: "/assets/images/gallery/3.jpg"
            },
            {
                original: "/assets/images/gallery/4.jpg",
                thumbnail: "/assets/images/gallery/4.jpg"
            },
            {
                original: "/assets/images/gallery/5.jpg",
                thumbnail: "/assets/images/gallery/5.jpg"
            },
            {
                original: "/assets/images/gallery/6.jpg",
                thumbnail: "/assets/images/gallery/6.jpg"
            },
        ]
    };

    open(e, image) {
        e.preventDefault()
        this.image = image
        this.setState({isOpen: true})
    }

    render() {
        const {translate, pics, name} = this.props;

        return (
            <div className="section-wrapper">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <Heading>{translate(name)}</Heading>
                            {/*<ImageGallery items={pics} showIndex={true}/>*/}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslate(GalleryNew);