import React, {Component} from 'react';
import './ourservices.scss';
import Heading from "../../common/heading/heading";
import {connect} from "react-redux";
import {withTranslate} from "react-redux-multilingual";

class OurServicesSection extends Component {
    render (){
        const {translate} = this.props;

        return (
            <div className="ourservices">
                <div className="container">
                    <Heading>{translate('services_title')}</Heading>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 d-flex">
                            <div className="featured-box style-2">
                                <div className="featured-box-wrap">
                                    <div className="featured-box-top">
                                        <div className="featured-box-icon"><i className="opal-icon-user"></i></div>
                                        <h3 className="featured-box-title text-uppercase">{translate('services_1')}</h3>
                                    </div>
                                    <div className="featured-box-bottom">
                                        <p className="featured-box-description" style={{textIndent: '15px'}}>{translate('services_1_info')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 d-flex">
                            <div className="featured-box style-2">
                                <div className="featured-box-wrap">
                                    <div className="featured-box-top">
                                        <div className="featured-box-icon"><i className="opal-icon-badge"></i></div>
                                        <h3 className="featured-box-title text-uppercase">{translate('services_2')}</h3>
                                    </div>
                                    <div className="featured-box-bottom">
                                        <p className="featured-box-description" style={{textIndent: '15px'}}>{translate('services_2_info')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 d-flex">
                            <div className="featured-box style-2">
                                <div className="featured-box-wrap">
                                    <div className="featured-box-top">
                                        <div className="featured-box-icon"><i className="opal-icon-like"></i></div>
                                        <h3 className="featured-box-title text-uppercase">{translate('services_3')}</h3>
                                    </div>

                                    <div className="featured-box-bottom">
                                        <p className="featured-box-description" style={{textIndent: '15px'}}>{translate('services_3_info')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        locale: state.Intl.locale
    }
};

export default connect(mapStateToProps)(withTranslate(OurServicesSection));