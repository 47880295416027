import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
// Import custom components
import CounterBlock from "./common/countup/countup";
import ArabkirBuilding from "./pages/buildings/arabkir/arabkir";
import {getBuildingInfoByName} from "../services/api";

class ComingSoon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    componentDidMount() {
        getBuildingInfoByName("Arabkir")
            .then(data => {
                this.setState({
                    data: data
                })
            });
    }

    render() {
        const {data} = this.state;
        return (
            <div className={`home-page-wrap`}>
                <Helmet>
                    <title>City Premium Realty</title>
                </Helmet>
                <div>
                    <ArabkirBuilding data={data}/>
                </div>

                <div className="section-wrapper">
                    <CounterBlock/>
                </div>

            </div>
        )

    }
}

export default ComingSoon;