import React, {Component} from 'react';
import "react-image-gallery/styles/css/image-gallery.css";

import {withTranslate} from "react-redux-multilingual";
import GalleryNew from "./gallerry-new";

class GalleryContainer extends Component {
    state = {
        isOpen: false,
        pics1: [
            {
                original: "/assets/images/general/nkar1.jpg",
                thumbnail: "/assets/images/general/nkar1.jpg"
            },
            {
                original: "/assets/images/general/outside-amenities.jpg",
                thumbnail: "/assets/images/general/outside-amenities.jpg"
            },
            {
                original: "/assets/images/general/map_changed1.jpg",
                thumbnail: "/assets/images/general/map_changed1.jpg"
            },
            {
                original: "/assets/images/general/parking-amenities.jpg",
                thumbnail: "/assets/images/general/parking-amenities.jpg"
            },
            {
                original: "/assets/images/general/pool-amenities.jpg",
                thumbnail: "/assets/images/general/pool-amenities.jpg"
            },
            {
                original: "/assets/images/general/8.JPG",
                thumbnail: "/assets/images/general/8.JPG"
            },
        ],
        pics2: [
            {
                original: "/assets/images/gallery/1.jpg",
                thumbnail: "/assets/images/gallery/1.jpg"
            },
            {
                original: "/assets/images/gallery/2.jpg",
                thumbnail: "/assets/images/gallery/2.jpg"
            },
            {
                original: "/assets/images/gallery/3.jpg",
                thumbnail: "/assets/images/gallery/3.jpg"
            },
            {
                original: "/assets/images/gallery/4.jpg",
                thumbnail: "/assets/images/gallery/4.jpg"
            },
            {
                original: "/assets/images/gallery/5.jpg",
                thumbnail: "/assets/images/gallery/5.jpg"
            },
            {
                original: "/assets/images/gallery/6.jpg",
                thumbnail: "/assets/images/gallery/6.jpg"
            },
        ],
    };

    render() {
        return (
            <>
                <GalleryNew pics={this.state.pics1} name={"building_photos"}/>
                <GalleryNew pics={this.state.pics2} name={"layout_photos"}/>
            </>
        )
    }
}

export default withTranslate(GalleryContainer);