import React, {Component} from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import CheckButton from 'react-validation/build/button';
import Textarea from 'react-validation/build/textarea'
import {isEmail, isEmpty, isMobilePhone} from 'validator';
import './contact-form.scss';
import {connect} from "react-redux";
import {withTranslate} from "react-redux-multilingual";
import Modal from "react-responsive-modal";
import {handleNewMessage} from "../../../services/api";

class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            phone: '',
            email: '',
            content: '',
            openBox: false
        }
    }

    required = (value) => {
        if (isEmpty(value)) {
            return <small className="form-text text-danger">This field is required</small>;
        }
    }

    email = (value) => {
        if (!isEmail(value)) {
            return <small className="form-text text-danger">Invalid email format</small>;
        }
    };

    phone = (value) => {
        if (!isMobilePhone(value)) {
            return <small className="form-text text-danger">Invalid Phone number format</small>;
        }
    }

    onSubmit = (e) => {
        const {onClose} = this.props;
        e.preventDefault();
        this.form.validateAll();

        if (this.checkBtn.context._errors.length === 0) {
            console.log(' success!' + '\n name: ' + this.state.name + '\n email: ' + this.state.email + '\n phone:' + this.state.phone + '\n content:' + this.state.content + '');
            onClose();
            const message = {
                id: null,
                clientName: this.state.name,
                phoneNumber: this.state.phone,
                email: this.state.email,
                text: this.state.content,
            };
            handleNewMessage(message).then(r => {
                console.log(r)
                this.setState({openBox: true});
            });
        }
    };

    onCloseModal = () => {
        this.setState({openBox: false});
    };


    render() {
        const {translate} = this.props;
        return (
            <div>
                <Form className="form-group-v1" onSubmit={e => this.onSubmit(e)} ref={c => {
                    this.form = c
                }}>
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="form-group">
                                <Input name="name" onChange={(e) => {
                                    this.setState({name: e.target.value})
                                }} value={this.state.name} type="text" placeholder={translate('your_name') + " *"}
                                       className="form-control" validations={[this.required]}/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="form-group">
                                <Input name="phone" onChange={(e) => {
                                    this.setState({phone: e.target.value})
                                }} value={this.state.phone} type="text" placeholder={translate('phone_number') + " *"}
                                       className="form-control" validations={[this.required, this.phone]}/>
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <Input name="email" onChange={(e) => {
                            this.setState({email: e.target.value})
                        }} value={this.state.email} type="email" placeholder={translate('your_email') + " *"}
                               className="form-control" validations={[this.required, this.email]}/>
                    </div>

                    <div className="form-group">
                        <Textarea onChange={(e) => {
                            this.setState({content: e.target.value})
                        }} value={this.state.content} className="form-control"
                                  placeholder={translate('how_can_we_help_you')} rows="3"
                                  validations={[this.required]}/>
                    </div>
                    <button type="submit" className="btn btn-primary mt-4 btn-block">{translate('submit')}</button>
                    <CheckButton style={{display: 'none'}} ref={c => {
                        this.checkBtn = c
                    }}/>
                </Form>
                <Modal open={this.state.openBox} onClose={this.onCloseModal} center>
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content quick-view-modal">
                            <div className="modal-body">
                                <h3 className="heading-title" style={{justifyContent: 'center', fontWeight: 'bold'}}>{translate('success_done')}</h3>
                                <h4 className="heading-title">{translate('soon_reply')}</h4>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        locale: state.Intl.locale
    }
};

export default connect(mapStateToProps)(withTranslate(ContactForm));