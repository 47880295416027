import request from './RequestManager';


// const backendHost = "http://localhost:8088";
const backendHost = "https://backend.cpr.am";

export function getBuildingInfoByName(name) {
    return request({
        method: 'GET',
        baseURL: backendHost,
        url: `/building/${name}`,
    }).then(response => response.data);
}

export function handleNewMessage(obj) {
    return request({
        method: 'POST',
        baseURL: backendHost,
        url: `/client/new`,
        data: obj
    }).then(response => response.data);
}