import React, {Component} from 'react';
import Slider from "react-slick";
import './slider.scss';
import {connect} from "react-redux";
import {withTranslate} from "react-redux-multilingual";

class SliderHome extends Component {
    render() {
        const {translate} = this.props;

        const settings = {
            dots: true,
            infinite: true,
            speed: 800,
            slidesToShow: 1,
            slidesToScroll: 1,
        };

        return (
            <div className={'slider-wrap position-relative'}>
                <Slider {...settings}>
                    <div className="slider-item position-relative">
                        <div className="slider-image"
                             style={{backgroundImage: `url("${process.env.PUBLIC_URL}/assets/images/general/nkar1.jpg")`}}>
                        </div>
                        <div className="container">
                            <div className="slider-content" style={{alignContent: 'baseline'}}>
                                <div className="text-center w-100">
                                    <h2 className="text-size-responsive text-primary" style={{ marginTop: '4%'}}>{translate('homeMain1_2')} </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        locale: state.Intl.locale
    }
};

export default connect(mapStateToProps)(withTranslate(SliderHome));