import React, {useState} from 'react';
import './arabkir.scss'

import {useTranslate} from "react-redux-multilingual";
import {Link} from "react-router-dom";
import ApartmentList from "./apartment-list";
import Elevator from "../../elevator/elevator";


function ApartmentContainer(props) {
    const t = useTranslate();
    const {
        setSelectedFloor, apartmentListData, selectedApartment, setSelectedApartment,
        hoveredId, imgUrl, selectedFloor, viewBox, setSelectedBuilding, selectedBuilding
    } = props;
    const [loading, setLoading] = useState(true);

    function needToSetLoading(newFloor, selectedFloor) {
        if ((newFloor === 2 || newFloor === 4 || newFloor === 6) && (selectedFloor === 2 || selectedFloor === 4 || selectedFloor === 6)) {
            return false;
        } else if ((newFloor === 3 || newFloor === 5 || newFloor === 7) && (selectedFloor === 3 || selectedFloor === 5 || selectedFloor === 7)) {
            return false;
        } else if (newFloor === 1 && selectedFloor === 1) {
            return false;
        } else if (newFloor === 8 && selectedFloor === 8) {
            return false;
        }
        return true;
    }
    return (
        <div>
            <div className="apartment-list">
                <div className="container">
                    <div className="row align-items-center" style={{textAlign: 'center'}}>
                        <div className="col-lg-3 col-md-4 col-4" id="building" style={{marginRight: 'auto'}}>
                            <div style={{textAlign: 'center', cursor: 'pointer'}}
                                 onClick={() => setSelectedBuilding(null)}>
                                <img
                                    src="https://fs.lightaffect.am/download?filePath=/assets/webacht/left-1632663239103.png"
                                    alt="Details"/>
                                <div className="details-text header-text-size-responsive noselect">
                                    {selectedBuilding} {t('building')}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 d-none d-lg-block">
                            <img
                                src="https://fs.lightaffect.am/download?filePath=/assets/webacht/building-small-200-1632663651597.png"
                                alt={"building"}/>
                        </div>
                        <div className="col-lg-3 col-5 " style={{marginLeft: 'auto'}}>
                            <Link to="/info">
                                <div style={{textAlign: 'center'}}>
                                    <img
                                        src="https://fs.lightaffect.am/download?filePath=/assets/webacht/details-1632661237567.png"
                                        alt="Details"/>
                                    <div className="details-text header-text-size-responsive noselect">
                                        {t('details')}
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{marginTop: '1%'}}>
                <div className="container" style={{justifyContent: 'center'}}>
                    <div className="row align-items-center" style={{justifyContent: 'center'}}>
                        <div className="col-lg-9 col-md-8 col-sm-8" id="building" style={{marginTop: '3%'}}>
                            <ApartmentList selectedFloor={selectedFloor}
                                           data={apartmentListData.buildingShortInfoList.find(el=>el.buildingName === selectedBuilding)} imgUrl={imgUrl}
                                           selectedApartment={selectedApartment}
                                           loading={loading} setLoading={setLoading}
                                           viewBox={viewBox}
                                           setSelectedApartment={setSelectedApartment}/>
                        </div>
                        <div className="col-lg-3 col-md-5 col-sm-6 ">
                            <Elevator setSelectedFloor={(floor) => {
                                if (needToSetLoading(floor, selectedFloor)) {
                                    setLoading(true);
                                }
                                setSelectedFloor(floor);
                            }}
                                      hoveredFloor={hoveredId} selectedFloor={selectedFloor}
                                      floors={[{id: 8, floor: 8}, {id: 7, floor: 7},
                                          {id: 6, floor: 6}, {id: 5, floor: 5}, {id: 4, floor: 4},
                                          {id: 3, floor: 3}, {id: 2, floor: 2}, {id: 1, floor: 1}
                                      ]} parkingFloors={[-1, -2]}/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ApartmentContainer