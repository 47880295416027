import React, {Component} from 'react';
// Custom Components
import HeaderOne from './components/common/headers/header-one';
import FooterOne from "./components/common/footers/footer-one";
import PushMenu from 'react-push-menu';
import LinkComponent from "./lib/menuPush/LinkComponent";
import './lib/menuPush/component.scss';
import {connect} from "react-redux";
import {withTranslate} from "react-redux-multilingual";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
        }

        this.load();
    }

    load = () => {
        fetch('/api/menu.json')
            .then((response) => response.json())
            .then((res) => {
                this.menu = this.updateMenu(res);
                this.setState({isLoading: false})
                document.querySelector(".loader-wrapper").style = "display: none";
            })
            .catch(reason => {
                console.log(reason)
            })
    };

    updateMenu(menu) {
        const {translate} = this.props;
        menu.forEach((item, i) => {
            item.name = translate(item.name);
            if (item.children !== []) {
                item.children.forEach(j => {
                    j.name = translate(j.name);
                })
            }
        });
        return menu
    }

    render() {


        return !this.state.isLoading && (
            <div className="opal-wrapper">
                <PushMenu
                    backIcon={<i className="fa fa fa-arrow-left"></i>}
                    expanderComponent={() => {
                        return (<i className="fa fa-chevron-right"></i>)
                    }}
                    autoHide={true}
                    nodes={{children: this.menu}}
                    type={'cover'}
                    linkComponent={LinkComponent}
                    propMap={{url: 'link'}}>
                    <div id="page" className="site">
                        <HeaderOne logoName={'logo.svg'} menu={this.menu}/>
                        {this.props.children}
                        <FooterOne logoName={'logo2.png'}/>
                    </div>

                </PushMenu>
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        locale: state.Intl.locale
    }
};

export default connect(mapStateToProps)(withTranslate(App));