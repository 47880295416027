import React from 'react';
import Heading from "../../common/heading/heading";
import {useTranslate} from "react-redux-multilingual";
import {Link} from "react-router-dom";

function OverviewSection() {

    const t = useTranslate();
    return (
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6 col-md-12 col-sm-12 content-left">
                    <img className="img-fluid"
                         src="https://fs.lightaffect.am/download?filePath=/assets/webacht/Bagramyan-1653987602559.png"
                         alt=""/>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 ">
                    <Heading>{t('aboutBaghramyanBuilding')}</Heading>
                    <p className="text-black" style={{textIndent: '20px'}}>
                        {t('aboutBaghramyanBuildingInfo1')}
                    </p>
                    <p className="text-black" style={{textIndent: '20px'}}>
                        {t('aboutBaghramyanBuildingInfo2')}
                    </p>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="icon-list">
                                <div className="icon-list-wrap">
                                    <ul>
                                        <li className="icon-list-items">
                                                <span className="icon-list-icon">
                                                    <i className="fa fa-angle-right"></i>
                                                </span>
                                            <span className="icon-list-text noselect">{t('new_amenities_1')}</span>
                                        </li>
                                        <li className="icon-list-items">
                                                <span className="icon-list-icon">
                                                    <i className="fa fa-angle-right"></i>
                                                </span>
                                            <span className="icon-list-text noselect" style={{color: '#3f4448'}}>{t('new_amenities_2')}</span>
                                        </li>
                                        <li className="icon-list-items">
                                                <span className="icon-list-icon">
                                                    <i className="fa fa-angle-right"></i>
                                                </span>
                                            <span className="icon-list-text noselect" style={{color: '#3f4448'}}>{t('new_amenities_3')}</span>
                                        </li>
                                        <li className="icon-list-items">
                                                <span className="icon-list-icon">
                                                    <i className="fa fa-angle-right"></i>
                                                </span>
                                            <span className="icon-list-text noselect " style={{color: '#3f4448'}}>{t('new_amenities_4')}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="icon-list">
                                <div className="icon-list-wrap">
                                    <ul>
                                        <li className="icon-list-items">
                                                <span className="icon-list-icon">
                                                    <i className="fa fa-angle-right"></i>
                                                </span>
                                            <span className="icon-list-text noselect" style={{color: '#3f4448'}}>{t('new_amenities_5')}</span>
                                        </li>
                                        <li className="icon-list-items">
                                                <span className="icon-list-icon">
                                                    <i className="fa fa-angle-right"></i>
                                                </span>
                                            <span className="icon-list-text noselect" style={{color: '#3f4448'}}>{t('new_amenities_6')}</span>
                                        </li>
                                        <li className="icon-list-items">
                                                <span className="icon-list-icon">
                                                    <i className="fa fa-angle-right"></i>
                                                </span>
                                            <span className="icon-list-text noselect" style={{color: '#3f4448'}}>{t('new_amenities_7')}</span>
                                        </li>
                                        <li className="icon-list-items">
                                                <span className="icon-list-icon">
                                                    <i className="fa fa-angle-right"></i>
                                                </span>
                                            <span className="icon-list-text noselect" style={{color: '#3f4448'}}>{t('new_amenities_8')}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row align-items-center" style={{marginTop: '5%'}}>
                <div className="col-lg-6 col-md-12 col-sm-12 content-left">
                    <Heading>{t('aboutBuilding')}</Heading>
                    <p className="text-black" style={{textIndent: '20px'}}>
                        {t('aboutBuildingInfo1')}
                    </p>
                    <p className="text-black" style={{textIndent: '20px'}}>
                        {t('aboutBuildingInfo2')}
                    </p>
                    <Link to="/arabkir">
                        <button type="button" className="btn btn-primary m-1">{t('select_apartment')}</button>
                    </Link>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <img className="img-fluid"
                         src="https://fs.lightaffect.am/download?filePath=/assets/webacht/home_1-1634410120314.jpg"
                         alt=""/>
                </div>
            </div>
        </div>
    )
}

export default OverviewSection