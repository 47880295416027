import React, {Component} from 'react';
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import Helmet from 'react-helmet';
import {Parallax} from 'react-parallax';
import Heading from "../../common/heading/heading";
import './about.scss';
import OurTeamBlock from "../../common/our-team/our-team";
import CallToActionSection from "../../sections/caltoaction/caltoaction";
import OurTeamSection from "../../sections/our-team/our-team";
import {connect} from "react-redux";
import {withTranslate} from "react-redux-multilingual";

class AboutPage extends Component {
    render() {
        const {translate} = this.props;

        return (
            <div className="about-page-wrap">
                <Helmet>
                    <title>City Premium Realty | About us</title>
                </Helmet>

                <div className="section-wrapper">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-12 col-sm-12 content-left">
                                <Heading>{translate('aboutUs')}</Heading>
                                <p className="text-black" style={{textIndent: '20px'}}>
                                    {translate('aboutUsInfo1')}
                                </p>
                                <p style={{textIndent: '20px'}}>
                                    {translate('aboutUsInfo2')}
                                </p>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <img className="img-fluid" src="/assets/imagewebp/about/banner_ab1.webp" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>

{/*                <div className="section-wrapper">
                    <Parallax
                        className={`section-parallax`}
                        bgImage={`${process.env.PUBLIC_URL}/assets/imagewebp/ourteam/img_bg.webp`}
                        bgImageAlt="the cat"
                        strength={500}
                    >
                        <div className="background-overlay"></div>
                        <div className="about-parallax  position-relative">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                        <div className="featured-box">
                                            <div className="featured-box-wrap">
                                                <div className="featured-box-top">
                                                    <div className="featured-box-icon"><i className="opal-icon-map"></i></div>
                                                    <h3 className="featured-box-title text-uppercase">Flexible, Contemporary Spaces</h3>
                                                </div>
                                                <div className="featured-box-bottom">
                                                    <p className="featured-box-description">We design and service
                                                        the next generation office building – one that feels more like a
                                                        full-service, lifestyle hotel</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                        <div className="featured-box">
                                            <div className="featured-box-wrap">
                                                <div className="featured-box-top">
                                                    <div className="featured-box-icon"><i className="opal-icon-user"></i></div>
                                                    <h3 className="featured-box-title text-uppercase">Dedicated Production And Planning Teams</h3>
                                                </div>
                                                <div className="featured-box-bottom">
                                                    <p className="featured-box-description">We are a team of 300 people who specialize in hospitality, technology, design, and production</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                        <div className="featured-box">
                                            <div className="featured-box-wrap">
                                                <div className="featured-box-top">
                                                    <div className="featured-box-icon"><i className="opal-icon-coffee"></i></div>
                                                    <h3 className="featured-box-title text-uppercase">Premium, Fresh Cuisine Made Onsite</h3>
                                                </div>
                                                <div className="featured-box-bottom">
                                                    <p className="featured-box-description">By studying the science of catering to large groups of people, particularly in a business context</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Parallax>

                </div>*/}
                <div className="section-wrapper">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <img className="img-fluid" src="/assets/imagewebp/about/banner_ab2.webp" alt=""/>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 content-right">
                                <Heading>{translate('aboutUsTitle2')}</Heading>
                                <p className="text-black" style={{textIndent: '20px'}}>
                                    {translate('aboutUs2Info1')}
                                    <b>{translate('aboutUs2InfoBold')}</b>
                                    {translate('aboutUs2Info2')}
                                </p>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        locale: state.Intl.locale
    }
};

export default connect(mapStateToProps)(withTranslate(AboutPage));