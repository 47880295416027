import React from 'react';
import './arabkir.scss'

import {useTranslate} from "react-redux-multilingual";
import BeforeAfter from "../../../common/before-after/before-after";
import {Link} from "react-router-dom";


function ApartmentDetails(props) {
    const {data, selectedFloor, selectedBuilding, selectedApartment, setSelectedApartment} = props;
    const t = useTranslate();
    let apartment = data.buildingShortInfoList.find(el => el.buildingName === selectedBuilding).floorData.find(el => el.floor === selectedFloor).apartments.find(el => el.id === selectedApartment);

    function getStatus(booked, sold) {
        if (booked) {
            return "booked";
        } else if (sold) {
            return "sold";
        } else {
            return "available";
        }
    }
    function commify(value){
        let chars = value.split("").reverse()
        let withCommas = [];
        for(let i = 1; i <= chars.length; i++ ){
            withCommas.push(chars[i-1])
            if(i%3===0 && i !== chars.length ){
                withCommas.push(".");
            }
        }
        return withCommas.reverse().join("");
    }
    function getStatusColor(booked, sold) {
        if (booked) {
            return "yellow";
        } else if (sold) {
            return "red";
        } else {
            return "green";
        }
    }

    return (
        <div>
            <div className="apartment-list">
                <div className="container">
                    <div className="row align-items-center" style={{textAlign: 'center'}}>
                        <div className="col-lg-3 col-md-4 col-4" id="building" style={{marginRight: 'auto'}}>
                            <div style={{textAlign: 'center', cursor: 'pointer'}}
                                 onClick={() => setSelectedApartment(null)}>
                                <img
                                    src="https://fs.lightaffect.am/download?filePath=/assets/webacht/left-1632663239103.png"
                                    alt="Details"/>
                                <div className="details-text">
                                    {selectedFloor} {t('floor')}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 d-none d-lg-block">
                            <img
                                src="https://fs.lightaffect.am/download?filePath=/assets/webacht/building-small-200-1632663651597.png"
                                alt={"building"}/>
                        </div>
                        <div className="col-lg-3 col-5 " style={{marginLeft: 'auto'}}>
                            <Link to="/info">
                                <div style={{textAlign: 'center'}}>
                                    <img
                                        src="https://fs.lightaffect.am/download?filePath=/assets/webacht/details-1632661237567.png"
                                        alt="Details"/>
                                    <div className="details-text">
                                        {t('details')}
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5 col-md-5 col-sm-5 content-left" style={{paddingTop: '5%'}} id="building">
                        <BeforeAfter left={apartment.beforeImg} right={apartment.afterImg}/>
                    </div>
                    <div className="col-lg-1 col-md-5"></div>

                    <div className="col-lg-5  col-md-5 col-sm-5" style={{paddingTop: '5%'}}>
                        <section className="widget widget_categories"
                                 style={{boxShadow: '0 0 10px hsl(0deg 0% 50% / 30%)'}}>
                            <h4 className="widget-title text-uppercase" style={{
                                textAlign: 'center',
                                fontFamily: 'Montserrat, sans-serif'
                            }}>{t('apartment_details')}</h4>
                            <ul>
                                <li className="cat-item">
                                    <span className="table-key" style={{fontSize: '21px'}}>{t('price')}</span>
                                    <span className="table-value" style={{fontSize: '26px'}}><b
                                        style={{color: '#cabe9f'}}>{commify(apartment.price+'')} $</b>*</span>
                                </li>
                                <li className="cat-item">
                                    <span className="table-key" style={{fontSize: '21px'}}>{t('area')}</span>
                                    <span className="table-value"
                                          style={{fontSize: '26px'}}><b
                                        style={{color: '#cabe9f'}}>{apartment.roomSquare} {t('m_square')}</b></span>
                                </li>
                                <li className="cat-item">
                                    <span className="table-key" style={{fontSize: '21px'}}>{t('floor')}</span>
                                    <span className="table-value" style={{fontSize: '26px'}}><b
                                        style={{color: '#cabe9f'}}>{selectedFloor}</b></span>
                                </li>
                                <li className="cat-item">
                                    <span className="table-key" style={{fontSize: '21px'}}>{t('rooms')}</span>
                                    <span className="table-value"
                                          style={{fontSize: '26px'}}><b
                                        style={{color: '#cabe9f'}}>{apartment.roomCount}</b></span>
                                </li>
                                <li className="cat-item">
                                    <span className="table-key" style={{fontSize: '21px'}}>{t('ceiling_height')}</span>
                                    <span className="table-value" style={{fontSize: '26px'}}><b
                                        style={{color: '#cabe9f'}}>3 {t('meter')}</b></span>
                                </li>
                                <li className="cat-item">
                                    <span className="table-key" style={{fontSize: '20px'}}>{t('status')}</span>
                                    <span className="table-value" style={{fontSize: '26px'}}><b
                                        style={{color: getStatusColor(apartment.booked, apartment.sold)}}>{t(getStatus(apartment.booked, apartment.sold))}</b></span>
                                </li>
                                <li> <span className="table-value" style={{fontSize: '15px', color: '#cabe9f'}}>{t('equal_dram')}</span></li>
                            </ul>
                            <div style={{textAlign: 'center', marginTop: '7%'}}>
                                <a href="tel:+37433511500">
                                    <button type="button" className="btn btn-primary m-1">{t('call')}</button>
                                </a>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApartmentDetails