import React from 'react';
import {Link} from 'react-router-dom'

function LogoImage(props) {

    return <Link to={`/`} className="custom-logo-link">
                <span className="screen-reader-text">City Premium Realty</span>
                <img src={"https://fs.lightaffect.am/download?filePath=/assets/webacht/Logo1-1628719404364.png"} alt="" className="img-fluid" />
            </Link>;
}
export default LogoImage;