import React from 'react';

import './elevator.css';


function Elevator(props) {
    const {floors, parkingFloors, selectedFloor, setSelectedFloor} = props;

    return (
        <>
            <div className="row" style={{justifyContent: 'center', marginTop: '3%'}}>
                <div className="col-lg-3 col-md-5 col-sm-4 d-flex" style={{justifyContent: 'center'}}>
                    <div className="featured-box style-2"
                         style={{boxShadow: '0 0 10px hsl(0deg 0% 50% / 30%)', padding: '27px 70px'}}>
                        <div className="featured-box-wrap">
                            <div className="featured-box-top">
                                <div className="featured-box-icon noselect" style={{paddingRight: '15px'}}>
                                    <img
                                    src={`${process.env.PUBLIC_URL}/assets/images/general/schindler-logo.png`}/>
                                </div>
                                <h3 className="featured-box-title text-uppercase noselect"
                                    style={{fontSize: '85px', paddingLeft: '15px', fontFamily: 'fantasy'}}>{selectedFloor}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{justifyContent: 'center'}}>
                <div className="col-lg-4 col-md-4 col-sm-4 col-4 d-flex" style={{justifyContent: 'center'}}>
                    <div className="btn-container">
                        <button className={`btn-floor noselect ${selectedFloor === 7 ? 'selected-button' : ''}`}
                                onClick={() => {
                                    if (selectedFloor !== 7) {
                                        setSelectedFloor(7);
                                    }
                                }}
                                data-set-floor="0">7
                        </button>
                    </div>
                </div>

                <div className="col-lg-4 col-md-4  col-sm-4 col-4 d-flex" style={{justifyContent: 'center'}}>
                    <div className="btn-container">
                        <button className={`btn-floor noselect ${selectedFloor === 8 ? 'selected-button' : ''}`}
                                onClick={() => {
                                    if (selectedFloor !== 8) {
                                        setSelectedFloor(8);
                                    }
                                }}
                                data-set-floor="0">8
                        </button>
                    </div>
                </div>
            </div>
            <div className="row" style={{justifyContent: 'center', marginTop: '10%'}}>
                <div className="col-lg-4 col-md-4 col-sm-4 col-4 d-flex" style={{justifyContent: 'center'}}>
                    <div className="btn-container">
                        <button className={`btn-floor noselect ${selectedFloor === 5 ? 'selected-button' : ''}`}
                                onClick={() => {
                                    if (selectedFloor !== 5) {
                                        setSelectedFloor(5);
                                    }
                                }}
                                data-set-floor="0">5
                        </button>
                    </div>
                </div>

                <div className="col-lg-4 col-md-4  col-sm-4 col-4 d-flex" style={{justifyContent: 'center'}}>
                    <div className="btn-container">
                        <button className={`btn-floor noselect ${selectedFloor === 6 ? 'selected-button' : ''}`}
                                onClick={() => {
                                    if (selectedFloor !== 6) {
                                        setSelectedFloor(6);
                                    }
                                }}
                                data-set-floor="0">6
                        </button>
                    </div>
                </div>
            </div>
            <div className="row" style={{justifyContent: 'center', marginTop: '10%'}}>
                <div className="col-lg-4 col-md-4 col-sm-4 col-4 d-flex" style={{justifyContent: 'center'}}>
                    <div className="btn-container">
                        <button className={`btn-floor noselect ${selectedFloor === 3 ? 'selected-button' : ''}`}
                                onClick={() => {
                                    if (selectedFloor !== 3) {
                                        setSelectedFloor(3);
                                    }
                                }}
                                data-set-floor="0">3
                        </button>
                    </div>
                </div>

                <div className="col-lg-4 col-md-4  col-sm-4 col-4 d-flex" style={{justifyContent: 'center'}}>
                    <div className="btn-container">
                        <button className={`btn-floor noselect ${selectedFloor === 4 ? 'selected-button' : ''}`}
                                onClick={() => {
                                    if (selectedFloor !== 4) {
                                        setSelectedFloor(4);
                                    }
                                }}
                                data-set-floor="0">4
                        </button>
                    </div>
                </div>
            </div>
            <div className="row" style={{justifyContent: 'center', marginTop: '10%'}}>
                <div className="col-lg-4 col-md-4 col-sm-4 col-4 d-flex" style={{justifyContent: 'center'}}>
                    <div className="btn-container">
                        <button className={`btn-floor noselect ${selectedFloor === 1 ? 'selected-button' : ''}`}
                                onClick={() => {
                                    if (selectedFloor !== 1) {
                                        setSelectedFloor(1);
                                    }
                                }}
                                data-set-floor="0">1
                        </button>
                    </div>
                </div>

                <div className="col-lg-4 col-md-4  col-sm-4 col-4 d-flex" style={{justifyContent: 'center'}}>
                    <div className="btn-container">
                        <button className={`btn-floor noselect ${selectedFloor === 2 ? 'selected-button' : ''}`}
                                onClick={() => {
                                    if (selectedFloor !== 2) {
                                        setSelectedFloor(2);
                                    }
                                }}
                                data-set-floor="0">2
                        </button>
                    </div>
                </div>
            </div>
            <div className="row" style={{justifyContent: 'center', marginTop: '20%'}}>

                <div className="col-lg-4 col-md-4  col-sm-4 col-4 d-flex" style={{justifyContent: 'center'}}>
                    <div className="btn-container">
                        <button className={`btn-floor noselect ${selectedFloor === -1 ? 'selected-button' : ''}`}
                                onClick={() => {
                                    if (selectedFloor !== -1) {
                                        setSelectedFloor(-1);
                                    }
                                }}
                                data-set-floor="0">-1
                        </button>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Elevator