import React, {Component} from 'react';
import {withTranslate} from 'react-redux-multilingual'
import './footer.scss';
import Copyright from "../copyright/copyright";
import ContactForm from "../contact-form/contact-form";


class FooterOne extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const {translate} = this.props;
        return (
            <footer className="site-footer">
                {/*<ShapeSVG position={`top`}/>*/}
                {/*Section #1*/}
                <div className="section-1 text-center">
                    <div className="container">
                        <h3 className="text-uppercase">{translate('are_you_interested')}</h3>
                        <h2 className="text-uppercase">{translate('its_time_to_discover')}</h2>
                        <h3 className="text-uppercase">{translate('the_building')}</h3>
                    </div>

                </div>

                {/*Section 2*/}
                <div className="section-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-12">
                                <h6 className="heading-title">{translate('office_location_label')}</h6>
                                <div className="icon-list">
                                    <ul>
                                        <li>
                                            <span className="icon-list-icon">
                                                <i className="fa fa-map-marker"></i>
                                            </span>
                                            <span className="icon-list-text">{translate('office_location_info')}</span>
                                        </li>
                                        <li>
                                            <span className="icon-list-icon">
                                                <i className="fa fa-phone"></i>
                                            </span>
                                            <span className="icon-list-text">(+374) 33 511 500</span>
                                        </li>
                                        <li>
                                            <span className="icon-list-icon">
                                                <i className="fa fa-phone"></i>
                                            </span>
                                            <span className="icon-list-text">(011) 50-00-11</span>
                                        </li>
                                        <li>
                                            <span className="icon-list-icon">
                                                <i className="fa fa-envelope"></i>
                                            </span>
                                            <span className="icon-list-text">sales@cpr.am</span>
                                        </li>
                                        <li>
                                            <span className="icon-list-icon">
                                                <i className="fa fa-envelope"></i>
                                            </span>
                                            <span className="icon-list-text">marketing@cpr.am</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-12">
                                <h6 className="heading-title">{translate('contact_agent')}</h6>
                                <div className="image-box-wrap">
                                    <div className="image-box-img"><img src="https://fs.lightaffect.am/download?filePath=/assets/webacht/logoMin-1628754695355.png" alt="" className={`img-fluid`}/></div>
                                    <div className="image-box-content">
                                        <h3 className="image-box-title">{translate('operator_name')}</h3>
                                        <span className="image-box-description">{translate('sales_agent')}</span>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-1 col-md-12"></div>
                            <div className="col-lg-4 col-md-12">
                                <h6 className="heading-title">{translate('enquire')}</h6>
                                <ContactForm onClose={() =>{}}/>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Section #2*/}
                {/*Section 3*/}
                <div className="section-3">
                    <div className="container text-center">
                        <div className="row">
                            <div className="col-sm-12">
                                <Copyright title="City Premium Realty"/>
                            </div>
                            <div className="col-sm-12">
                                <div className="social">
                                    <a href="https://www.facebook.com/city.premium.realty" target="_blank">
                                        <span className="screen-reader-text">Facebook</span>
                                        <i className="fa fa-facebook"/>
                                    </a>
                                    <a href="https://www.instagram.com/citypremiumrealty" target="_blank">
                                        <span className="screen-reader-text">Instagram</span>
                                        <i className="fa fa-instagram"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}


export default withTranslate(FooterOne);
