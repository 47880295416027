import React, {Component} from 'react';
import Heading from "../../common/heading/heading";
import {connect} from "react-redux";
import {withTranslate} from "react-redux-multilingual";

class OurPartners extends Component {
    render() {
        const {translate} = this.props;

        return (
            <div>
                <div className="container">
                    <Heading>{translate('our_partners')}</Heading>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 d-flex" style={{justifyContent: 'center'}}>
                            <a href="https://evoca.am" target="_blank">
                                <div className="featured-box style-2 hover-background-white" style={{backgroundColor: '#fff !important'}}>
                                    <div className="featured-box-wrap">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/general/evoca2.png`}
                                             style={{justifyContent: 'center'}} alt="partner"/>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-3 col-md-6 d-flex" style={{justifyContent: 'center'}}>
                            <a href="https://www.mottura.it/ru/homepage-russian"
                               target="_blank">
                                <div className="featured-box style-2 hover-background-white">
                                    <div className="featured-box-wrap">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/general/mottura.png`}
                                             style={{paddingTop: '10%'}} alt="partner"/>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-3 col-md-6 d-flex" style={{justifyContent: 'center'}}>
                            <a href="https://profal.am" target="_blank">
                                <div className="featured-box style-2 hover-background-white">
                                    <div className="featured-box-wrap">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/general/profal.png`}
                                             alt="partner"/>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-3 col-md-6 d-flex" style={{justifyContent: 'center'}}>
                            <a href="https://ilift.am" target="_blank">
                                <div className="featured-box style-2 hover-background-white">
                                    <div className="featured-box-wrap">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/general/schindler-logo2.png`}
                                             alt="partner"/>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        locale: state.Intl.locale
    }
};

export default connect(mapStateToProps)(withTranslate(OurPartners));