import React, {useState} from 'react';


import {useTranslate} from "react-redux-multilingual";

let animationInProgress = true;

function Building(props) {
    const {data, selectedId, setSelectedId, hoveredId, setHoveredId, selectedItem, setSelectedItem} = props;
    const [loading, setLoading] = useState(true);

    const t = useTranslate();

    const lineHoveredStyle = {
        fillOpacity: ".4",
        strokeOpacity: ".8"
    };
    const textHoveredStyle = {
        fillOpacity: ".7"
    };

    function setHover(id, value) {
        if (value) {
            setHoveredId(id);
        } else {
            setHoveredId(null);
        }
    }

    function timeout(id) {
        setTimeout(function () {
            if (id !== 18 && animationInProgress) {
                setHover(id, true);
                timeout(id + 1);
            }
        }, 900);
    }

    if (!data || data === []) {
        return (<div>
        </div>);
    }
    return (
        <div>
            <div style={!loading ? {display: 'none'} : {}}>
                <svg viewBox="0 0 1537 978"
                     xmlns="http://www.w3.org/2000/svg">
                    <circle cx="750" cy="350" fill="none" stroke="#cabe9f" strokeWidth="15" r="55"
                            strokeDasharray="164.93361431346415 56.97787143782138">
                        <animateTransform attributeName="transform" type="rotate" calcMode="linear"
                                          values="0 750 350;360 750 350" keyTimes="0;1" dur="1s" begin="0s"
                                          repeatCount="indefinite"/>
                    </circle>
                </svg>
            </div>
            <div style={loading ? {display: 'none'} : {}}>
                <svg viewBox="0 0 1537 978"
                     xmlns="http://www.w3.org/2000/svg">
                    <image className="img-fluid noselect" alt="" onLoad={() => {
                        setLoading(false);
                        timeout(data.buildingShortInfoList[0].floorData[0].id);
                    }}
                           href={data.imgPath}/>
                    {data && data.buildingShortInfoList && data.buildingShortInfoList.map((el, i) => {
                        return (
                            el.floorData.map((item, index) => {
                                if (item.floor === -1) {
                                    return (<div/>);
                                }
                                return (
                                    <a key={index} id={item.id} className={"floor noselect"} rel="noopener"
                                       onMouseEnter={() => {
                                           animationInProgress = false;
                                           setHover(item.id, true);
                                       }}
                                       onMouseLeave={() => {
                                           setHover(item.id, false);
                                       }}
                                       onClick={() => {
                                           setSelectedItem(item.floor, el.buildingName);
                                       }}
                                       title={item.id}>
                                        <polyline className="line polyline noselect "
                                                  points={item.points}
                                                  style={hoveredId === item.id ? lineHoveredStyle : {}}/>
                                        <rect
                                            className={`floor-text noselect  ${hoveredId === item.id ? 'fill-white' : ''}`}
                                            style={
                                                hoveredId === item.id ? textHoveredStyle : {}
                                            } x={item.popupX} y={item.popupY} rx="0" ry="100" width="520"
                                            height="200"/>
                                        <text
                                            className={`floor-text noselect ${hoveredId === item.id ? 'building-style' : ''}`}
                                            x={item.buildingTextX}
                                            y={item.buildingTextY}
                                            textAnchor="middle"
                                            style={hoveredId === item.id ? textHoveredStyle : {}}>
                                            {el.buildingName}
                                        </text>
                                        <text
                                            className={`floor-text noselect ${hoveredId === item.id ? 'fill-black' : ''}`}
                                            x={item.floorTextX}
                                            y={item.floorTextY}
                                            style={hoveredId === item.id ? textHoveredStyle : {}}>
                                            {item.floor} {t('floor')}
                                        </text>
                                        <text
                                            className={`floor-text noselect ${hoveredId === item.id ? 'fill-black' : ''}`}
                                            x={item.availableTextX}
                                            y={item.availableTextY}
                                            style={hoveredId === item.id ? textHoveredStyle : {}}>
                                            {item.availableCount && item.availableCount !== 0 ? item.availableCount + ' ' + t('available') : t('sold')}
                                        </text>
                                    </a>
                                );
                            })
                    );
                    })
                    }

                </svg>
            </div>
        </div>
    )
}

export default Building