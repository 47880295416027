import React, {useState} from 'react';
import './arabkir.scss'

import {useTranslate} from "react-redux-multilingual";


function ApartmentList(props) {
    const {data, setSelectedApartment, imgUrl, loading, setLoading, viewBox, selectedFloor} = props;
    const [hoveredId, setHoveredId] = useState(0);
    const t = useTranslate();

    const lineHoveredStyle = {
        fillOpacity: ".4",
        strokeOpacity: ".8"
    };
    const textHoveredStyle = {
        fillOpacity: ".9",
    };

    function setHover(id, value) {
        if (value) {
            setHoveredId(id);
        } else {
            setHoveredId(null);
        }
    }

    function getAvailabilityText(sold, booked) {
        if (sold) {
            return "sold"
        } else if (booked) {
            return "booked";
        } else {
            return "available";
        }
    }

    const floor = data.floorData.find(el => el.floor === selectedFloor);
    return (
        <div>
            <div style={!loading ? {display: 'none'} : {}}>
                <svg viewBox="0 0 1100 1030"
                     xmlns="http://www.w3.org/2000/svg">
                    <circle cx="550" cy="350" fill="none" stroke="#cabe9f" strokeWidth="10" r="35"
                            strokeDasharray="164.93361431346415 56.97787143782138">
                        <animateTransform attributeName="transform" type="rotate" calcMode="linear"
                                          values="0 550 350;360 550 350" keyTimes="0;1" dur="1s" begin="0s"
                                          repeatCount="indefinite"/>
                    </circle>
                </svg>
            </div>
            <div style={loading ? {display: 'none'} : {}}>
                <svg viewBox={viewBox}
                     xmlns="http://www.w3.org/2000/svg">
                    <image className="img-fluid" alt=""
                           onLoad={() => setLoading(false)}
                           href={imgUrl}/>
                    {floor.apartments && floor.apartments.map((item, index) => {
                        return (
                            <a key={index} id={item.id} className={"floor noselect"} rel="noopener"
                               onMouseEnter={() => {
                                   setHover(item.id, true);
                               }}
                               onMouseLeave={() => {
                                   setHover(item.id, false);
                               }}
                               onClick={() => {
                                   if (item.clickable) {
                                       setHover(item.id, true);
                                       setTimeout(() => {
                                           setSelectedApartment(item.id);
                                       }, 100);
                                   }
                                   setHover(item.id, true);
                               }}
                               title={item.id}>
                                <polyline className="line polyline noselect"
                                          points={item.points}
                                          style={hoveredId !== item.id ? lineHoveredStyle : {}}/>

                                {
                                    (item.roomSquare !== '0' || (item.sold || item.booked)) &&
                                    <>
                                        <rect
                                            className={`sold-popup noselect ${item.sold ? 'soldFill' : item.booked ? 'bookedFill' : 'defaultFill'}`}
                                            style={hoveredId !== item.id ? textHoveredStyle : {}}
                                            // style={textHoveredStyle }
                                            x={item.soldPopupX} y={item.soldPopupY} rx="5" ry="100" width="180"
                                            height="40"/>
                                        < text
                                            className={`soldTextStyle noselect`}
                                            x={item.soldTextX}
                                            y={item.soldTextY}
                                            textAnchor="middle"
                                            style={hoveredId !== item.id ? textHoveredStyle : {}}>
                                            {/*style={textHoveredStyle}>*/}
                                            {item.sold || item.booked ? t(getAvailabilityText(item.sold, item.booked)) : item.roomSquare + ' ' + t('m_square')}
                                        </text>
                                    </>
                                }
                                {
                                    item.roomSquare === '0' &&
                                    <>
                                        <rect
                                            className={`apartment-popup noselect  ${hoveredId === item.id ? 'borderBlack' : ''}`}
                                            style={hoveredId === item.id ? textHoveredStyle : {}}
                                            // style={hoveredId === item.id ? textHoveredStyle : {}}
                                            x={item.popupX} y={item.popupY} rx="0" ry="100" width="230"
                                            height="85"/>
                                        <text
                                            className={`apartment-price-text noselect ${item.sold ? 'soldStyle' : item.booked ? 'bookedStyle' : 'availableStyle'}`}
                                            x={item.availabilityX}
                                            y={item.availabilityY}
                                            textAnchor="middle"
                                            style={hoveredId === item.id ? textHoveredStyle : {}}>
                                            {t(getAvailabilityText(item.sold, item.booked))}
                                        </text>
                                        <text
                                            className={`apartment-price-text noselect`}
                                            x={item.priceX}
                                            y={item.priceY}
                                            textAnchor="middle"
                                            style={hoveredId === item.id ? textHoveredStyle : {}}>
                                            {item.price + ' $'}
                                        </text>
                                    </>
                                }
                                {/*<line x1={item.lineX1} y1={item.lineY1}
                                      x2={item.lineX2} y2={item.lineY2}
                                      stroke="black"
                                      strokeWidth={1}
                                      className={`apartment-price-text`}
                                      textAnchor="middle"
                                      style={hoveredId === item.id ? textHoveredStyle : {strokeOpacity: 0}}/>
                                <text
                                    className={`apartment-floor-text `}
                                    x={item.roomNameX}
                                    y={item.roomNameY}
                                    textAnchor="middle"
                                    style={hoveredId === item.id ? textHoveredStyle : {}}>
                                    {item.roomName}
                                </text>
                                <text
                                    className={`apartment-floor-text ${hoveredId === item.id ? 'fill-black' : ''}`}
                                    x={item.roomSquareX}
                                    y={item.roomSquareY}
                                    style={hoveredId === item.id ? textHoveredStyle : {}}>
                                    {item.roomSquare}
                                </text>*/}
                            </a>
                        );
                    })
                    }
                    <image x={920} y={0}
                           href={`${process.env.PUBLIC_URL}/assets/images/general/north136.png`}/>
                </svg>
            </div>
        </div>
    )
}

export default ApartmentList