import React, {Component} from 'react';
import '../explore-amenities/explore-amenities.scss';
import PopupVideo from "../../common/popup-video/popup-video";
import {connect} from "react-redux";
import {withTranslate} from "react-redux-multilingual";

class OfficeSpaceSection extends Component {
    render() {
        const {translate} = this.props;
        return (
            <div className="amenities-general amenities-bg">
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12 align-self-center pb-maxmd">
                        <div className="w-lg-75 mx-auto">
                            <h3 className="text-uppercase text-primary">{translate('live_title_1')}</h3>
                            <h2 className="text-uppercase mb-4">{translate('live_title_2')}</h2>
                            <p className="text-black" style={{textIndent: '20px'}}>{translate('live_text_1')}
                                <b>
                                    {translate('live_text_bold')}
                                </b>
                            </p>
                            <p className="text-black" style={{textIndent: '20px'}}>
                                {translate('live_text_2')}
                            </p>
                            <div style={{padding: '10px'}}>
                                <PopupVideo
                                    title={translate('watch_live1')}
                                    videoId="njfosqALSc4"
                                    channel={"custom"}
                                    url={"https://rtsp.me/embed/bk8kEiK6/"}
                                />
                            </div>
                            <div style={{padding: '10px'}}>
                                <PopupVideo
                                    title={translate('watch_live2')}
                                    videoId="njfosqALSc4"
                                    channel={"custom"}
                                    url={"https://rtsp.me/embed/25zdek6H/"}
                                />
                            </div>
                            <div style={{padding: '10px'}}>
                                <PopupVideo
                                    title={translate('watch_live3')}
                                    videoId="njfosqALSc4"
                                    channel={"custom"}
                                    url={"https://rtsp.me/embed/SNNns6b8/"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 align-self-center text-center">
                        <img className="img-fluid" src={this.props.src} alt=""/>
                    </div>
                </div>


            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        locale: state.Intl.locale
    }
};

export default connect(mapStateToProps)(withTranslate(OfficeSpaceSection));