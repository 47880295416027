import React, {Component} from 'react';
import './language-switch.scss';
import {connect} from "react-redux";
import store from "../../../../store";
import { IntlActions, withTranslate } from 'react-redux-multilingual'
import {useTranslation} from 'react-i18next';


class LanguageSwitchBlock extends Component {
    constructor(props){
        super(props);

        this.data = {
            en: 'united-states.png',
            am: 'armenia.png',
            ru: 'russia.png',
        };

        this.state = {
            image: this.data[this.props.locale],
            locale: this.props.locale
        }
    }

    changeLanguage(lang) {
        store.dispatch(IntlActions.setLocale(lang))
    }

    render (){
        const {translate} = this.props;
        return (
            <div className={`language-switch-wrap`}>
                <div className="header-language dropdown-parent">
                        <span><img src={`${process.env.PUBLIC_URL}/assets/imagewebp/language/${this.state.image}`} alt=""/></span>
                    <div className="group-dropdown">

                        <div className="language-content">
                            <ul className="language_inner">
                                <li><a href="#" onClick={(e) => {e.preventDefault(); this.changeLanguage('am')}}><img src={`${process.env.PUBLIC_URL}/assets/imagewebp/language/armenia.png`} alt=""/>{translate('am')}</a></li>
                                <li><a href="#" onClick={(e) => {e.preventDefault(); this.changeLanguage('en')}}><img src={`${process.env.PUBLIC_URL}/assets/imagewebp/language/united-states.png`} alt=""/>{translate('en')}</a></li>
                                <li><a href="#" onClick={(e) => {e.preventDefault(); this.changeLanguage('ru')}}><img src={`${process.env.PUBLIC_URL}/assets/imagewebp/language/russia.png`} alt=""/>{translate('ru')}</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        locale: state.Intl.locale
    }
}

export default connect(mapStateToProps)(withTranslate(LanguageSwitchBlock));

