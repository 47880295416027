import React, {Component} from 'react';
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import Helmet from "react-helmet";
import './the-building.scss';
import CounterBlockStyle from "../../common/countup/countup-style";
import GallerrySection from "../../sections/gallerry/gallerry";
import OverviewSection from "../../sections/overview/overview";
import AmenitiesSection from "../../sections/amenities/amenities";
import OurServicesSection from "../../sections/ourservices/ourservices";
import NeighborhoodsSection from "../../sections/neighborhoods/neighborhoods";
import CarouselPage2 from "../../common/slider/slider2";
import CounterBlock from "../../common/countup/countup";
import OurPartners from "../../sections/ourpartners/ourpartners";

class TheBuildingPage extends Component {
    render() {

        return (
            <div className={`the-building-wrap`}>
                <Helmet>
                    <title>City Premium Realty | The Building Page</title>
                </Helmet>
                <div className="section-wrapper">
                    <OverviewSection/>
                </div>
                <div className="section-wrapper text-center">
                    <CounterBlock/>
                </div>
                <div className="section-wrapper">
                    <CarouselPage2/>
                </div>
                <div className="section-wrapper pl-6 pr-6">
                    <AmenitiesSection/>
                </div>
                <div className="section-wrapper">
                    <OurServicesSection/>
                </div>
                <div className="section-wrapper">
                    <NeighborhoodsSection/>
                </div>
                <div className="section-wrapper">
                    <OurPartners/>
                </div>
                <div className="section-wrapper">
                    <div className="gallerry-wrap">
                        <GallerrySection flexRow={true}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default TheBuildingPage