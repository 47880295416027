import React, {Component} from 'react';
import {withTranslate} from "react-redux-multilingual";

class Copyright extends Component {
    render (){
        const {translate} = this.props;
        return (
            <div className="copyright">
                <p>© 2022 <a href="/">{this.props.title}</a>. {translate('all_rights_reserved')}.</p>

            </div>
        )
    }
}
export default withTranslate(Copyright);