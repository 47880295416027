import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
// Import custom components
import SliderHome from "./common/slider/slider";
import OverviewSection from "./sections/overview/overview";
import CounterBlock from "./common/countup/countup";
import OurServicesSection from "./sections/ourservices/ourservices";
import AmenitiesSection from "./sections/amenities/amenities";
import NeighborhoodsSection from "./sections/neighborhoods/neighborhoods";
import GallerrySection from "./sections/gallerry/gallerry";
import ImageBox from "./common/image-box/image-box";
import {connect} from "react-redux";
import {withTranslate} from "react-redux-multilingual";
import OurPartners from "./sections/ourpartners/ourpartners";

class Home1 extends Component {
    render() {
        const {translate} = this.props;
        return (
            <div className={`home-page-wrap`}>
                <Helmet>
                    <title>City Premium Realty</title>
                </Helmet>

                <div className="section-wrapper pt-0 pb-0">
                    <SliderHome/>
                </div>
                <div className="section-wrapper">
                    <OverviewSection/>
                </div>

                <div className="section-wrapper">
                    <CounterBlock/>
                </div>

                <div>
                    <div className="categories-banner">
                        <div className="container-fluid">
                            <div className="row no-gutters">
                                <div className="col-xl-3 col-md-6">
                                    <ImageBox
                                        subTitle=""
                                        title={translate('albumZoomText1')}
                                        bgImage={`${process.env.PUBLIC_URL}/assets/images/general/6-small.jpg`}
                                    >
                                    </ImageBox>
                                </div>
                                <div className="col-xl-3 col-md-6">
                                    <ImageBox
                                        subTitle=""
                                        title={translate('albumZoomText2')}
                                        bgImage={`${process.env.PUBLIC_URL}/assets/images/general/8-small.jpg`}
                                    >
                                    </ImageBox>
                                </div>
                                <div className="col-xl-3 col-md-6">
                                    <ImageBox
                                        subTitle=""
                                        title={translate('albumZoomText4')}
                                        bgImage={`${process.env.PUBLIC_URL}/assets/images/general/ktrvacq.jpg`}
                                    >
                                    </ImageBox>
                                </div>
                                <div className="col-xl-3 col-md-6">
                                    <ImageBox
                                        subTitle=""
                                        title={translate('albumZoomText3')}
                                        bgImage={`${process.env.PUBLIC_URL}/assets/images/general/parking.jpg`}
                                    >
                                    </ImageBox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="section-wrapper pl-6 pr-6">
                    <AmenitiesSection/>
                </div>
                <div className="section-wrapper">
                    <OurServicesSection/>
                </div>
                <div className="section-wrapper">
                    <NeighborhoodsSection/>
                </div>
                <div className="section-wrapper">
                    <OurPartners/>
                </div>
                <div className="section-wrapper">
                    <div className="container">
                        <GallerrySection flexRow={false}/>
                    </div>
                </div>

            </div>
        )

    }
}

const mapStateToProps = (state) => {
    return {
        locale: state.Intl.locale
    }
};

export default connect(mapStateToProps)(withTranslate(Home1));