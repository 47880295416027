import React from 'react';
import './arabkir.scss'

import {useTranslate} from "react-redux-multilingual";
import Building from "./building";
import BuildingDetailsSection from "./building-details-section";


function BuildingContainer(props) {
    const t = useTranslate();
    const {data, hoveredId, setHoveredId, selectedItem, setSelectedItem} = props;
    return (
        <div>
            <div style={{marginTop: '1%', justifyContent: 'center'}}>
                <div className="container" style={{justifyContent: 'center'}}>
                    <div className="row align-items-center" style={{justifyContent: 'center'}}>
                        <div className="col-lg-9 col-md-8 col-sm-8 content-left" id="building">
                            <Building data={data} hoveredId={hoveredId} setHoveredId={setHoveredId}
                                      selectedItem={selectedItem}
                                      setSelectedItem={setSelectedItem}/>
                        </div>
                        <div className="col-lg-3 col-md-5 col-sm-5">
                            <BuildingDetailsSection setSelectedItem={setSelectedItem}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BuildingContainer