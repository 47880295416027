import React, {Component} from 'react';

import './popup-video.scss';

class PopupVideo extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
        }
    }

    render() {
        return (
            <div className={`video-popup`}>
                <a href="#" style={{display: 'block !important'}} onClick={(e) => {e.preventDefault(); this.setState({isOpen: true})}}>
                    <span className="icon video-icon"><i className="fa fa-play"/></span>
                </a>
                <a href="#" style={{display: 'block !important'}} onClick={(e) => {e.preventDefault(); this.setState({isOpen: false})}}>
                    <span className="icon video-icon"><i className="fa fa-stop"/></span>
                </a>
                <a href="#" style={{display: 'block !important'}} onClick={(e) => {e.preventDefault(); this.setState({isOpen: true})}}>
                    <span className="title">{this.props.title}</span>
                </a>
                <iframe className="stream-video"
                        style={this.state.isOpen ? {marginTop: '3%'} : {display: 'none'}}
                        src={this.props.url} frameBorder="0"
                        allowFullScreen/>
            </div>
        )
    }
}

export default PopupVideo